@media print {

  header {
    display: none;
  }

  footer, .footer {
    display: none;
  }


  img {
    display: none;
  }

  /* Typografie */

  body {
    font: 12pt;
  }

  html {
    font-size: 12pt;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 16pt;
  }

  a::after {
    content: ' ('attr(href) ') ';
    text-decoration: underline;
  }

  a[href^='mailto']:after,
  a[href^='tel']:after {
    content: none;
  }

  /* Block specific */
  /* Inschrijven niewusbrief */
  .inschrijven-nieuwsbrief {
    display: none;
  }

  .inschrijven-nieuwsbrief-sidebar {
    display: none;
  }

  /* FAQ-block */

  
  /* BLOCK HULP NODIG */
  .js--help-widget-closed {
    display: none;
  }
  /* BLOCK HULP NODIG */

  /* BLOCK Mogelijk ook interessant */
  vej-lookalike-vacature-carousel {
    display: none;
  }
  /* BLOCK Mogelijk ook interessant */
}