.awesomplete [hidden] {
  display: none;
}

.awesomplete .visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.awesomplete {
  position: relative;
  display: inline-block;
  float: left;

  &.-clearable {
    @include flexbox();
  }
}


/*END*/

.awesomplete>input {
  display: block;
  width: 100%;
}

.awesomplete>ul {
  position: absolute;
  z-index: 10;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  min-width: 300px;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0;
  border-radius: .3em;
  background: $color-white;
  box-shadow: .05em .2em .6em $transparent-black-20;
  text-shadow: none;
}

.awesomplete>ul:empty {
  display: none;
}

//   @supports (transform: scale(0)) {
//     .awesomplete>ul {
//       transition: .3s cubic-bezier(.4, .2, .5, 1.4);
//       transform-origin: 1.43em -.43em;
//     }

//     .awesomplete>ul[hidden], .awesomplete>ul:empty {
//       display: block;
//       transition-timing-function: ease;
//       transform: scale(0);
//       opacity: 0;
//     }
//   }


.awesomplete>ul>li {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  margin-top: 1px;
  padding: .65rem 1rem;
  cursor: pointer;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  /*add VDAB styling*/
  color: $color-grey-a500;

  &:first-child {
    margin-top: 0;
  }
}

.awesomplete>ul>li[aria-selected='true'] {
  color: $color-white;
  background: $color-blue-500;

  .awesomplete-metadata {
    color: $color-white;
  }
}

.awesomplete>ul>li[aria-selected='true']:hover {
  opacity: 1;
  color: $color-white;
  background: $color-blue-500;
}

.awesomplete>ul>li:hover {
  color: $color-white;
  background: $color-blue-500;

  .awesomplete-metadata {
    color: $color-white;
  }
}

.awesomplete mark {
  font-weight: 500;
  color: $color-blue-500;
  background: none;
}

.awesomplete li:hover mark {
  color: $color-white;
}

.awesomplete li[aria-selected='true'] mark {
  font-weight: 500;
  color: $color-white;
  background: color-blue-500;
}

.awesomplete li[aria-selected='true'] .mark {
  font-weight: 500;
  color: $color-blue-500;
  background: none;
}

.awesomplete-metadata {
  font-size: $font-size-base;
  font-style: italic;
  margin-bottom: 0;
  white-space: normal;
  color: $color-grey-800;
}

.awesomplete-noresults {
  font-family: $brand-font-regular;
  font-size: $font-size-base;
  margin-bottom: 0;
  padding: 1rem;
  white-space: normal;
}

/* extra class: does the same as standard highlight but for dev use*/
.awesomplete li[aria-selected='true'].highlight {
  font-weight: 500;
  opacity: 1;
  color: $color-white;
  background: $color-blue-500;
}


/*
* CSS for multiselect 
*/

.awesomplete.-multi {
  ul {
    overflow-y: scroll;
    max-height: 200px;
  }

  li:not(.-close) {
    padding-left: 2rem;

    input {
      position: absolute;
      margin-top: .25rem;
      margin-left: -1.5rem;
    }

    label {
      display: block;
      cursor: pointer;
    }
  }

  .-close {
    position: sticky;
    bottom: -.1rem; //don't ask me why, dont know
    cursor: default;
    border-top: 1px solid $color-grey-300;
    background-color: $color-white;

  }
}