$navbar-height: 80px;

.c-main-navbar-container {
  height: 120px;
}

.c-main-navbar {
  position: fixed;
  z-index: 115; //overlay is 100
  width: 100%;

  &__skip-link {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: color 0 ease-in;
    transition: none;

    &:focus {
      clip: unset;
      width: unset !important;
      height: unset !important;
      margin-top: $spacing-s !important;
      margin-left: $spacing-s !important;
    }
  }

  &__vlaanderen-header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    margin-top: 0; //overrules for o-wrapper
    margin-bottom: 0;
    padding: 0 $spacing-m;
  }

  &__vlaanderen-logo {
    // image spacing fix FIX-JS
    float: left;
  }

  &__sub-menu {
    @include reset-list;
    @include flexbox;

    &.-auth {
      display: none;

      .-auth & {
        display: block;
      }
    }

    &.-anon {
      display: block;

      .-auth & {
        display: none;
      }
    }
  }

  &__sub-menu-item {
    display: inline-block;
    margin: 0;
    padding-right: $spacing-s;
    padding-left: $spacing-s;
    color: $color-grey-800;
    border-right: 1px solid $color-grey-900;

    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }

  /* Search typehead desktop */
  &__search {
    @include flexbox;
    @include align-items(center);
    margin-left: auto;
    padding: 0;
  }

  //added c-btn to make it more specific so button doesn't override this border radius
  &__search-btn.c-btn.icon-search {
    @include align-self(stretch);
    padding: $spacing-xxs !important;
    color: $color-white;
    border-color: $color-blue-500;
    border-radius: 0 5px 5px 0;
    background-color: transparentize($color-blue-700, .6) !important;

    &:focus {
      box-sizing: border-box;
    }

    &:hover {
      background-color: transparentize($color-blue-700, .6) !important;
    }

    &:before {
      padding-right: 0;
    }
  }

  /* search dropdown mobile */
  &__dropdown-search {
    position: absolute;
    top: $navbar-height;
    right: 0;
    left: 0;
    padding: $spacing-l $spacing-m;
    border-top: 6px solid $color-blue-900;
    background-color: $color-white;
    box-shadow: 2px 4px 8px 0 $color-box-shadow;
  }

  &__search-input {
    width: 100%;
    border-radius: 5px !important;
  }

  /* end search*/

  &__vdab-header {
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
    //overrules for o-wrapper
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 !important;
  }


  &__vdab-logo {
    height: 32px;
    padding: $spacing-m $spacing-m $spacing-m 0;
  }

  &__vdab-logo-img {
    height: 32px;
  }

  &__vdab-menu-button {
    @extend .icon;
    @extend .icon-mobile-menu;
    @include align-self(stretch);
    @include flexbox();
    @include align-items(center);
    font-size: 1.25rem;
    padding: $spacing-m;
    text-decoration: none;
    color: $color-white;
    background-color: transparentize($color-blue-700, .6);

    &:before {
      padding-right: $spacing-xs;
    }

    &.is-active {
      @extend .icon-close;
      background-color: $color-blue-900;
    }

    &:hover {
      text-decoration: none;
      color: $color-white;
      background-color: $color-blue-900;
    }

    &:visited {
      color: $color-white;
    }
  }

  // Code positioned here because: Error: You may not @extend an outer selector from within @media. //
  &__vdab-menu-search.is-active {
    @extend .icon-close;
    background-color: $color-blue-900;
  }


  // !! Mega menu dropdown !! //
  &__megamenu-wrapper {
    overflow-y: auto;
    border-top: 6px solid $color-blue-900;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(248, 247, 248, 1) 50%); //sass-lint:disable-line no-color-literals
    box-shadow: 2px 4px 8px 0 $color-box-shadow;

    .-auth & {
      background: linear-gradient(90deg, rgba(248, 247, 248, 1) 50%, rgba(255, 255, 255, 1) 50%); //sass-lint:disable-line no-color-literals
    }
  }

  &__megamenu-container {
    @include display-grid;
    position: relative;
    margin: auto;
    padding-left: 0;

    grid-template-columns: 2fr 1fr;
    grid-template-areas: 'menulinks account';

    .-auth & {
      grid-template-columns: 1fr 2fr;
      grid-template-areas: 'account menulinks';
    }
  }

  &__megamenu {
    @include display-grid;
    padding-right: $spacing-l;
    padding-bottom: $spacing-l;
    background-color: $color-white;

    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);

    .-auth & {
      padding-right: 0;
      padding-left: $spacing-l;

      grid-area: 'menulinks';
    }
  }

  &__account-auth-menu, &__account-anon-menu {
    margin-top: 0;
    padding-bottom: $spacing-l;

    grid-area: account;
  }

  &__account-anon-menu {
    padding: $spacing-l $spacing-l $spacing-l $spacing-l;
    background-color: $color-grey-100; //Coloured again, on top of background linair to be more robust when scaling. The gradient is in % and takes the paddings on the side with in its calculation so the cutoff between grey/white is not exactly 60-40% and therefore too fragile to determine

    .-auth & {
      display: none;
    }
  }

  &__account-auth-menu {
    position: relative;
    display: none;
    background-color: $color-grey-100;

    grid-area: account;

    .-auth & {
      display: block;
    }
  }

  &__account-title {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    height: 32px;

    &:after {
      content: '';
    }
  }

  &__account-title-text {
    margin: 0;
    color: $color-blue;
  }

  &__account-avatar {
    margin-right: calc(3.5rem - 42px);
  }

  &__account-menu-list {
    margin-left: $spacing-l;
  }


  &__megamenu-section {
    margin: 0;
  }

  &__megamenu-item {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__megamenu-logout {
    display: none;
  }

  &__megamenu-logout-link {
    display: block;
    padding: $spacing-s $spacing-m $spacing-xs $spacing-m;
    text-decoration: none;
    color: $color-red-700;
    border-bottom: 1px solid $color-grey-400;

    &:visited, &:hover {
      text-decoration: none;
      color: $color-red-700;
    }
  }

  &__megamenu-link {
    display: block;
    padding: .38rem $spacing-m;
    text-decoration: none;
    color: $headings-color;

    &:visited {
      color: $headings-color;
    }

    &:hover {
      text-decoration: none;
      color: darken($color-blue-500, 15%);
    }

    &.has-notification {
      @include flexbox;
      @include align-items(center);
    }
  }

  &__megamenu-toggle {
    display: none;
  }

  &__megamenu-toggle-link {
    display: block;
    padding: $spacing-xs $spacing-m $spacing-xs;
  }

  &__routing-link {
    line-height: $baseline*1.2;
    padding: $spacing-l $spacing-s $spacing-s $spacing-m;
    color: $color-blue-500;
    border-bottom: 0;

    /*
    &:after {
      content: '';
    }
    */

    &:visited {
      color: $color-blue-500;
    }

    &:hover {
      color: darken($color-blue-500, 15%);
    }
  }

  &__footer {
    display: none;
  }

  &__footer-cta {
    margin: $spacing-m 0 $spacing-xs $spacing-m;
  }

  &__footer-subnavigation {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__footer-link {
    display: block;
    padding: $spacing-s $spacing-xs $spacing-s $spacing-m;
    color: $color-blue;
    border-bottom: 1px solid $color-grey-400;

  }

  &__footermenu {
    margin: 0;
    padding: 0;
    background-color: $color-blue-900;

    li {
      margin: 0;
      border-bottom: 1px solid $color-grey-400;
    }
  }

  &__footermenu-link {
    display: block;
    padding: $spacing-xs $spacing-m;
    text-decoration: none;
    color: $color-white;

    &:visited, &:hover {
      text-decoration: none;
      color: $color-white;
    }
  }

  &__footer-vlaanderen-menu {
    position: relative;
  }

  &__footer-languages {
    @include flexbox;
    @include justify-content(flex-end);
    position: absolute;
    right: 0;
    margin-right: $spacing-m;
    list-style: none;
    transform: translateY(25%);

    li {
      margin: 0 0 0 $spacing-s;
    }
  }

  &__language-link {
    text-decoration: none;
    color: $color-grey-900;

    &.is-active {
      font-weight: 500;
    }
  }

  &__account-bullet {
    //TODO FIX
    position: absolute;
    left: 0;
  }


  &__overlay {
    position: fixed;
    z-index: 110;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparentize($color-grey-a500, .5);
  }
}


/* MEDIA Q*/
@include screenLarge() {
  .c-main-navbar {
    &__megamenu-container {
      .-auth & {
        padding-left: 1.5rem;
      }
    }
  }
}

@include screenMedium() {
  .c-main-navbar {
    &__megamenu-link {
      padding: $spacing-xs $spacing-m;
    }
    &__megamenu-container {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto;
      grid-template-areas: 'account'
      'menulinks'
      'dropdown-footer';

      .-auth & {
        padding-left: 0;

        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto;
        grid-template-areas: 'account'
        'menulinks'
        'dropdown-footer';
      }
    }

    &__megamenu {
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;

      grid-gap: 0;
      grid-template-columns: 1fr;

      .-auth & {
        padding-left: 0;
      }
    }

    &__megamenu-wrapper {
      box-sizing: border-box;
      height: calc(100vh - #{$navbar-height} - 40px);
      background: $color-white;

      .-auth & {
        background: $color-white;
      }
    }

    &__account-auth-menu {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      background-color: $color-white;
    }

    &__account-title {
      padding-left: $spacing-m;

      span.icon-arrow-right {
        //I know, I just don't gaf anymore rn
        order: 2;
      }
    }

    &__account-title-text {
      @include flex-grow(1);
    }

    &__account-avatar {
      order: 1;
    }

    &__account-menu-list {
      margin: 0;

      column-count: 2;
    }

    &__account-anon-menu {
      padding-top: $spacing-m;
      padding-bottom: 0;
      padding-left: $spacing-m;
      background-color: $color-white;
    }

    &__section-list {
      column-count: 2;
    }

    &__routing-link {
      padding-top: $spacing-l;
      padding-bottom: $spacing-s;
      border-bottom: 1px solid $color-grey-400;

      &:after {
        // //icon extend manuel cause it doesnt work in MQ
        // font-family: 'icons';
        // font-weight: normal;
        // font-style: normal;
        // font-variant: normal;
        // line-height: 1;
        // text-transform: none;
        // //end
        // float: right;
        content: '\E006'; //overrule because something from btns bleeds - weird

        order: 2;
        // content: char-from-code($icon-arrow-right);
        // text-align: center;
      }
    }

    &__megamenu-item {
      border-bottom: 1px solid $color-grey-400;
      &:not(.c-main-navbar__megamenu-toggle) {
        @include align-items(center);
        display: inline-flex;
        height: 48px;
      }
    }

    &__megamenu-logout {

      .-auth & {
        display: block;
      }
    }

    &__footer {
      display: block;

      grid-area: dropdown-footer;
    }

    &__footer-cta {
      .-auth & {
        display: none;
      }
    }

    &__footer-subnavigation li:nth-child(2) {
      .-auth & {
        display: none;
      }
    }
  }
}

@include screen() {
  .c-main-navbar-container {
    height: $navbar-height;
  }

  .c-main-navbar {
    &__megamenu-wrapper {
      overflow-x: hidden;
      height: calc(100vh - #{$navbar-height});
    }

    &__vlaanderen-header {
      display: none;
    }

    &__vdab-header {
      margin-right: 0;
    }

    &__vdab-logo {
      margin-right: auto;
    }

    &__vdab-menu-search {
      @include align-self(stretch);
      @include flexbox();
      @include align-items(center);
      font-size: 1.25rem;
      padding: 0 $spacing-s;
      color: $color-white !important;
      outline: 0 !important;

      &:visited {
        color: $color-white;
      }
    }
  }
}

@include tablet {
  .c-main-navbar {

    &__section-list, &__account-menu-list {
      column-count: 1;
    }

    &__megamenu-item {
      &:not(.c-main-navbar__megamenu-toggle) {
        display: inline-block;
        height: unset;
      }
    }

    &__megamenu-toggle {
      display: block;
    }

    &__search-input {
      border-width: 1px !important;
    }
  }

  .js-show-sr-only\@tablet {
    @include sr-only();
  }
}

@media print {
  .c-main-navbar-container {
    display: none;
  }

  .c-main-navbar {
    display: none;
  }
}

@media (max-width: 60em) {
  .c-main-navbar__megamenu-wrapper {
      overflow-x: hidden;
      height: calc(100dvh - 80px);
  }
}

@media (min-width: 60em) {
  .c-main-navbar {
    &__search-input {
      border-width: 0 !important;
      border-radius: 5px 0 0 5px !important;
    }
  }
}