.c-doormat {
  @include screen() {
    columns: 2;
  }
  @include phone() {
    columns: 1;
  }

  columns: 4;

  li {
    margin-top: 0;
  }
}