.c-panel {
  padding: $spacing-s $spacing-m  $spacing-l;
  border: 2px solid $color-blue-200;
  border-radius: 8px;
  background-color: $color-blue-100;
  
  &__header {
    overflow: hidden;
    margin-bottom: $spacing-s;

    &__title {
      float: left;
      margin: 0;
    }
  }
  &__actions {
    text-align: right;

    a {
      margin-left: $spacing-s;
    }
  }
}

