//grid overrule from burger version

.c-main-navbar__megamenu-link {
  .c-wg-navbar__partners & {
    padding-left: 0; //overrule from burger
  }
}

.o-wrapper {
  .c-wg-navbar__partners & {
    position: relative;
  }
}

.c-wg-navbar {
  &__vdab-menu {
    @extend .icon;
    @extend .icon-mobile-menu;
    @include align-self(stretch);
    @include flexbox();
    @include align-items(center);
    font-size: 1.25rem;
    padding: $spacing-m $spacing-s;
    text-decoration: none;
    color: $color-white;
    background-color: $color-indigo-700;

    &:before {
      padding-right: $spacing-xs;
    }

    &.is-active {
      @extend .icon-close;
      background-color: $color-blue-900;
    }

    &:hover {
      text-decoration: none;
      color: $color-white;
      background-color: $color-blue-900;
    }

    &:visited {
      color: $color-white;
    }
  }

  .c-main-navbar__routing-link.-auth-menu::after, .c-main-navbar__account-title::after {
    display: none;
  }

  .c-dropdown__menu.c-wg-navbar__other-accounts-menu { // only on desktop
    overflow-y: scroll;
    min-width: 320px;
    max-height: 80vh; 

    .-show-all {
      @extend .pt1, .pb1;
      border-top: 1px solid $color-grey-500;
    }
  }

  &__other-vestiging { // legacy
    @extend .u-show\@screen;
  }
  
  &__other-accounts-menu { 
    line-height: 120%;
  }
 
  &__search-btn {
    @include align-self(stretch);
    padding: $spacing-xxs !important;
    color: $color-white;
    border: 0 !important;
    border-radius: 0 5px 5px 0 !important;
    background-color: $color-indigo-700;

    &:focus {
      box-sizing: border-box;
    }

    &:hover {
      background-color: $color-blue-900 !important;
    }

    &:before {
      padding-right: 0;
    }
  }

  &__search-input {
    border: 0 !important;
    border-radius: 5px 0 0 5px !important;
  }

  &__account-avatar.c-account-bullet {
    text-decoration: none;
    color: $color-white;
    background-color: $color-blue-900;

    &:visited {
      text-decoration: none;
      color: $color-white;
    }

    &:hover {
      text-decoration: none;
      color: darken($color-white, 15%);
    }
  }

  &__wz-site-nav {
    position: absolute;
    right: 0;
    bottom: $spacing-l;
    display: none;

    &.c-btn { // more specific than the general c-btn display:inline-block
      display: none;
    }

    .-auth & {
      display: block;
    }
  }

  &__partner-site-nav {
    position: absolute;
    top: $spacing-l;
    right: 0;
  }

  &__partners {
    border-top: 1px solid $color-indigo-900;

    &:not(.-partner &) {
      display: none;
    }
  }

  &__partner-menu {
    column-count: 5;
  }

  &__partner-link {
    display: inline-block;
    padding-left: 0;
  }
}

.c-wg-footer {
  color: $color-white;
  background-color: $color-indigo-900;
}

.c-footer__button-group__button {

  .c-wg-footer & {
    background-color: transparent;
  }
  
}

@include screenLarge() {
  .c-wg-navbar {
    &__wz-site-nav {
      right: $spacing-m !important;
    }
  }
}

@include screenMedium() {
  .c-main-navbar {
    &__footer-subnavigation {
      .c-navbar-wg & {
        display: block;
      }
    }
  }

  .o-wrapper {
    .c-wg-navbar__partners & {
      margin-right: 0; //overrule from burger
      margin-left: 0; //overrule from burger
    }
  }

  .c-main-navbar__megamenu-container {
    .c-wg-navbar & {
      grid-template-areas: 'account'
      'menulinks'
      'partners'
      'dropdown-footer';
    }
  }

  .c-main-navbar__megamenu-link {
    .c-wg-navbar__partners & {
      padding-left: $spacing-m; //reset from desktop overrule
    }
  }

  .c-wg-navbar {
    &__wz-site-nav {
      display: none !important;
    }

    &__partner-site-nav {
      display: none;
    }

    &__partners {
      border-top: 0;

      grid-area: partners;
    }

    &__partner-menu {
      column-count: 2;
    }

    &__partner-link {
      display: block;
      padding-left: $spacing-m;
    }
    
    .c-main-navbar__account-title::after {
      display: block;

      order: 2;
    }
  }
}

@include screen() {
  .c-wg-navbar__other-accounts-menu, .c-wg-navbar__other-vestiging { // legacy
    &-selector.c-btn.-ninja, &-selector.c-btn.-ninja:focus {
      padding-top: $spacing-xs;
    }
    
    &-options {
      margin-top: $spacing-xs;
      padding-left: 0;
      list-style: none;
      text-align: left;
  
      li {
        margin: 0;
      }

      li a {
        display: block;
        padding: $spacing-xs $spacing-s;
        cursor: default; //the negate the effects of clickable topelements like a button
        white-space: nowrap;
        text-decoration: none;
        color: $color-grey-900;

        &:hover, &:focus {
          text-decoration: none;
          color: $color-white;
          background-color: $color-blue;

          .u-text-meta {
            color: $color-white;
          }
        }

        &[disabled] {
          color: $color-grey-600;
          background-color: $color-grey-100;

          &:hover {
            cursor: not-allowed;
            color: $color-grey-600;
          }
        }
      }
    }
  }
}

@include tablet {
  .c-wg-navbar {
    &__partner-menu {
      column-count: 1;
    }
  }
}