//sass-lint:disable-all no-color-literals no-color-hex
.c-page-header {
  padding-top: .01rem;
  padding-bottom: $spacing-l;
  background: linear-gradient(269.95deg, rgba(12, 136, 126, .8) -10.24%, rgba(73, 180, 170, .8) 99.55%);

  &.-home {
    background: radial-gradient(62.07% 385.69% at 73.23% 50%, #49B4AA 0%, #0C887E 100%);
  }

  &.-jobs {
    background: radial-gradient(62.1% 385.9% at 73.27% 50%, #2D89CC 0%, #004475 100%);
  }

  &.-wg {
    background: radial-gradient(62.07% 385.69% at 73.23% 50%, $color-burgundy-500 0%, $color-burgundy-900 100%);
  }

  &.-opleidingen {
    background: radial-gradient(62.1% 385.9% at 73.27% 50%, #FDB85E 0%, #E27D10 100%);
  }

  &.-orienteren {
    background: radial-gradient(62.07% 385.69% at 73.23% 50%, #7F2AA7 0%, #48006A 100%);
  }

  &.-extranet {
    background: radial-gradient(62.07% 385.69% at 73.23% 50%, #B88A02 0%, #76640A 100%);
  }

  &.-no-widget {
    padding-bottom: 0;
  }

  .o-wrapper .o-2-col {
    @include flexbox();
    
  }
  
  &__textblock {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include tablet {
      padding: $spacing-m 0;
    }
    padding: $spacing-l 0;
    color: $color-white;

    flex-grow: 1;

  }

  &__imageblock {
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(flex-end);
    @include screen {
      display: none;
    }
  }

  &__image {
    width: auto;
    max-width: 100%;
    max-height: 300px;
    margin-top: $spacing-s;
  }

  &__action-link-list {
    margin-top: $spacing-s;
  }

  &__widget-container {
    margin-top: -6rem;
  }

}

.c-page-header.-home, .c-page-header.-jobs {
  .c-page-header__textblock {
    .basic-form.c-search-widget__form {

      @media (max-width: 1313px) {
        flex-wrap: wrap;
      }

      @media (max-width: 1270px) {
        @include flex-direction(column);
        @include align-items(flex-start);
      }

      
    }

    .c-search-widget__field-column.-two-fields {
      width: 100%;

      flex-grow: 1;
    }

    .-no-bg .c-search-widget__submit-button {
      flex-basis: unset;
    }

    .c-search-widget__field-column {
      flex: unset;
      width: 100%;
      //max-width: 220px;

      flex-grow: 1;
      -webkit-box-flex: unset;
      -webkit-flex: unset;
      flex-basis: 275px;

      @media (max-width: 1342px) {
        flex-basis: 219px;
      }

      @media (max-width: 1270px) {
        flex-basis: unset;
      }

      &.-no-radius {
        flex-grow: 1;
      }

    }
  }
}

.c-page-header.-jobs {
  .c-page-header__textblock {
    .c-search-widget__field-column {
      flex-basis: 370px;

      @media (max-width: 1262px) {
        flex-basis: unset;
      }
    }
  }
}


@include screen() {
  .c-page-header {
    padding-bottom: 0;
  }
}

.c-page-header.-thin {
  .c-page-header__imageblock {
    display: none;
  }

  .c-page-header__intro-text {
    margin-bottom: 0;
  }

  .c-page-header__action-link-list {
    display: none;
  }
}

.c-page-header.-thin.-home {
  .c-page-header__wrapper-sidebar {
    row-gap: 0;
  }
}