
.c-widget-lbc {
  position: relative;
  padding: $spacing-l;
  border: 3px solid $color-blue-700;
  border-radius: $spacing-xs;
  background-color: $color-grey-100;

  &__title {
    width: calc(100% - 7rem);
  }

  &__circle {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    position: absolute;
    top: -3rem;
    right: 1rem;
    width: 7rem;
    height: 7rem;
    border-radius: 100%;
    background-color: $color-yellow-700;
  }

  &__circle-text {
    margin-bottom: 0;
    text-align: center;
    color: $color-indigo-900;
  }

  &__img {
    width: 7rem;
    height: 7rem;
  }
}