.c-widget {

  // used in wg widgets
  &__header {
    @include flexbox();
    @include justify-content(space-between);

    align-items: center;
  }

  &__header-actions {
    @include flexbox();
    @include align-items(center);
  }

  &__header-search {
    white-space: nowrap;

    & .c-btn,
    & .c-btn:focus {
      box-sizing: border-box;
      padding-right: 0;
      padding-left: .3rem;
    }
  }

  &__wrapper {
    margin-top: 0;
  }
}

@include tablet() {
  .c-slat__content {
    flex-direction: column;
  }

  .c-dropdown {
    position: absolute;

    order: -1;
    align-self: flex-end;
  }

  .c-breadcrumb__item {
    margin-top: 0;
  }
}

@include screen() {
  .c-widget {
    &__header\@mobile {
      text-align: center;
      white-space: nowrap;
    }

    &__header-tab\@mobile {
      display: inline-block;
      box-sizing: border-box;
      width: 50%;
      border-right: 1px solid $color-grey-400;
      background-color: $color-white;

      &.-active {
        border-top: 1px solid $color-grey-400;
        border-bottom: 0;
      }

      &:not(.-active) {
        color: $color-muted;
        border-top: 0;
        border-bottom: 1px solid $color-grey-400;
        background: $color-grey-200;
      }
    }

    &__header-tab-title\@mobile {
      margin: 0;
      padding: $spacing-m 0;
    }
  }
}