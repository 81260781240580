.c-verbolia-widget {
  @include tablet() {
    border-bottom: 1px solid $color-grey-600 ;
  }

  &__list {
    @include tablet() {
      columns: 2;
    }
    @include phone() {
      columns: 1;
    }

    columns: 4;
    li {
      margin-top: 0;
    }
  }

  &__header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(flex-start);
    @include phone() {
      &:hover {
        background-color: $color-blue-100;
      }

      &:focus-within {
        outline: 2px solid $color-indigo-900;
        background-color: $color-blue-100;

      }
    }
  }

  &__title {
    @include tablet() {
      h2 {
        margin-bottom: 0;
        color: $color-blue-500;

      }
    }

  }

  &__toggle-btn {
    @include tablet() {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      width: 2.5rem;
      height: 2.5rem;
      color: $color-blue-500;
      border: 0;
      border-radius: 100%;
      background-color: transparent;
  
      &:before {
        padding-right: 0;
      }
      &:hover {
        background-color: $color-blue-100;
      }
  
      &:focus {
        border: 3px solid $color-indigo-900;
        background-color: $color-blue-100;

      }
    }

  }


}