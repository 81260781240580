.c-modal {
  position: fixed;
  z-index: 125;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 600px;
  max-width: 90vw;
  max-height: 90vh;
  transform: translate(-50%, -50%);
  border-radius: .5rem;
  background-color: $color-white;
  box-shadow: 0 0 34px transparentize($color-black, .92); //TO DO gelijktrekken met dialogbox

  &.-medium {
    width: 900px;
  }

  &.-large {
    width: 1200px;
  }

  &.-full-screen {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }

  &__header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    padding: $spacing-s $spacing-m;
    border-bottom: 2px solid $color-blue-700;
  }

  &__title {
    margin: 0;
  }

  &__close {
    font-size: 1.5rem;
    padding: 0 1rem;
    color: $color-blue-700;
  }

  &__body {
    overflow: auto;
    max-height: 70vh;
    padding: $spacing-m;

    &.-form {
      border-radius: .5rem;
      background-color: $color-blue-100;
    }

    .-full-screen & {
      max-height: 80vh;
    }
  }

  &__split-body {
    @include flexbox();
    overflow: hidden;
    max-height: 90vh;

    &.-left {
      @include flex-direction(column);
      overflow: auto;
      width: 66%;
      padding: $spacing-l;
    }

    &.-right {
      @include justify-content(center);
      @include align-items(flex-end);
      overflow: hidden;
      width: 33%;
      padding-top: $spacing-ll;
      border-radius: 0 .5rem .5rem 0;
      background-color: $color-blue-100;
    }
  }

  &__image-box {
    //position: absolute;
    //bottom: 24px;
    max-width: 100%;
    max-height: 70vh;
  }

  &__image {
    max-height: 100%;

    object-fit: contain;
  }

  &__cookies {
    z-index: 140;
  }

  &__cookielist {
    @include flexbox();
    @include flex-direction(column);

    &:not(:firt-child) {
      margin-top: $spacing-m;
    }

  }

  &__cookielabel {
    margin-right: $spacing-s !important; //overrules bcs of checkbox class...
    margin-bottom: $spacing-xs;
    padding: 0 !important;

    flex-shrink: 0;
  }

  &__cookielist-icon:before {
    text-decoration: none;
    color: $color-blue-500;

    &:focus {
      text-decoration: none;
    }

    &:visited {
      color: $color-blue-500;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__cookie-overlay {
    position: fixed;
    z-index: 135;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparentize($color-grey-a500, .5);
  }
}

//add to the body when modal is shown
.o-modal-open {
  overflow: hidden;
  height: 100vh; //necessary to avoid scrolling in cases where drupal has added overflow-x to the HTML tag
}

@include tablet() {
  .c-modal {

    &.-full-screen\@tablet {
      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      max-height: 100vh;

      .c-modal__body.-form {
        height: 100%;
        max-height: unset;
      }
    }

    &.-bottom-drawer\@tablet {
      top: unset;
      bottom: 0;
      left: 0;
      overflow: auto;
      transform: unset;
    }

    &.-medium, &.-large {
      max-width: 100vw;
      max-height: 90vh;
    }

    &__split-body {
      &.-left {
        width: 100%;
        padding: 1rem;
      }


      &.-right {
        display: none;
      }
    }

    &__cookielist {
      @include flex-direction(column);
      position: relative;
      padding-right: 1rem;

      &.is-open {
        border-right: 1px solid $color-purple-a500;
      }
    }

    &__cookielabel {
      width: auto;

      flex-shrink: unset;
    }

    &__cookielist-icon {
      position: absolute;
      top: -12px;
      right: 0;
      padding: 11px;
      transform: translateX(50%);
      text-decoration: none;
      background: $color-white;
    }
  }
}