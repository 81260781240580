.c-search-widget {
  @include screen {
    border-radius: 0;
  }
  padding: $spacing-m;
  padding-bottom: 0;
  border-radius: $spacing-xs;

  &.-no-bg {
    padding: 0;
    
  }

  &__title {
    margin-top: 0;
  }

  &__title-link {
    text-decoration: none;
    color: $headings-color !important;

    &:visited {
      color: $headings-color !important;
    }

    &:hover {
      color: darken($color-blue-500, 15%) !important;
    }
    &:focus {
      text-decoration: underline;
    }
  }

  &__form-block {
    @include screen {
      @include flexbox;
      @include flex-direction(column);
    }
    padding-bottom: $spacing-m;

    &.-large {
      margin-bottom: $spacing-l;
    }
  }

  &__header {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    @include screen {
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(flex-start);
    }
    margin-bottom: $spacing-s;
  }

  &__action-link-list {

    // &.-horizontal {
    //   @include screen {
    //     @include flex-direction(column);
    //     @include align-items(flex-start);
    //   }
    // }

    &._\@show-screen {
      @include screen {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(flex-start);
        //margin-bottom: $spacing-m;
      }
      display: none;
    }

    &._\@hide-screen {
      @include screen {
        display: none;
      }
    }
  }

  &__form {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(flex-end);
    @include screen {
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(flex-start);
    }

    gap: .5rem;
  }

  &__field-column {
    @include flex(1);
    @include screen {
      width: 100%;
      margin-right: 0;
      margin-bottom: $spacing-s;
    }
    margin-top: 0 !important;
    //margin-right: $spacing-m;

    &.-two-fields {
      @include flexbox;
      @include align-items(flex-end);
      @include tablet {
        @include flex-direction(column);
        @include align-items(flex-start);
      }
    }

    &.-simple-typeahead {
      margin-right: 0;
    }
  }

  &__label {
    font-weight: unset !important;
    width: 100%;
    color: $color-grey-800;

    .-no-bg & {
      color: $color-white;
    }
  }

  &__input {
    width: 100%;
  }

  &__large-field {
    @include flex-grow(10);
    @include tablet {
      @include flex-grow(unset);
      width: 100%;
    }
    margin-top: 0 !important;
  }

  &__small-field {
    @include flex-grow(1);
    @include tablet {
      @include flex-grow(unset);
      width: 100%;
      margin-left: 0;
    }
    //display: none;
    max-width: 170px;
    margin-top: 0 !important;
    margin-left: $spacing-xs;
  }

  &__small-select {
    width: 100%;
  }

  &__submit-button {
    @include flex-basis(10rem);
    @include screen {
      @include flex-basis(unset);
      width: 100%;
      margin: $spacing-s 0;
    }

    .-no-bg & {
      @include screen() {
        box-sizing: border-box;
      }
      box-sizing: content-box;
      white-space: nowrap;
    }
  }

  &__jobdomein-list {
    @include reset-list;
    @include column-count(4);
    @include screen {
      @include column-count(2);
      display: none;
      padding-top: $spacing-s;
    }
    @include tablet {
      @include column-count(1);
    }
  }
  &__erase-button.icon-close {
    margin-left: -51px;
  }

  &__location-button.icon-marker {
    margin-left: -51px;

    &:before {
      color: $color-blue-500;
    }
  }

  &__icon-marker {
    &:before {
      font-size: 1.5rem !important;
    }
  }

  &__jobdomein-block {
    @include screen {
      margin-bottom: 0;
      padding-top: 0;
    }
    padding-top: $spacing-m;
    padding-bottom: $spacing-m;
    border-top: 1px solid $color-grey-500;
  }

  &__jobdomein-title {
    @include screen {
      display: block;
      width: 100%;
      margin: 0;
      padding-top: $spacing-m;
      cursor: pointer;
    }
    padding: 0;
    cursor: default;
    border: 0;
    outline: 0;
    background-color: transparent;
  }

  &__jobdomein-title-arrow {
    @include screen {
      display: inline-block;
      margin-right: $spacing-xs;
    }
    display: none;

  }

  &__filter-amount {
    margin-left: $spacing-xs;
    color: $color-grey-800;
  }

  &__title\@screen {
    @include screen {
      display: block;
      margin-bottom: $spacing-s;
    }
    display: none;
  }

  &__filters {
    line-height: 2.5rem;
    margin-top: 1.5rem;

  }

  &__filters-label {
    display: inline;
    color: $color-grey-800;
  }


  &__clear-filters {
    text-decoration: none;
    color: $color-grey-700;

    &:hover {
      text-decoration: none;
    }
  }

}

.alle-beroepen-link {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-top: 1rem;
  text-decoration: none;
  .field-icon {
    font-size: 2rem;
  }
  span {
    line-height: 1;
  }
  &:hover {
    text-decoration: none;
  }
}