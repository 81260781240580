/**
 * The use of important on utilities
 * Force utility classes to be immutable by applying !important to their declarations.
 * http://csswizardry.com/2016/05/the-importance-of-important/
 * http://davidtheclark.com/on-utility-classes/
 */

//  =========================== Margins  =========================== 

.u-m0 {
  margin: 0 !important;
}

.u-mt0 {
  margin-top: 0 !important;
}

.u-mr0 {
  margin-right: 0 !important;
}

.u-mb0 {
  margin-bottom: 0 !important;
}

.u-ml0 {
  margin-left: 0 !important;
}

.u-mb0\@screen {
  @include screen() {
    margin-bottom: 0 !important;
  }
}

.u-mt0\@screen {
  @include screen() {
    margin-top: 0 !important;
  }
}

.u-ml0\@screen {
  @include screen() {
    margin-left: 0 !important;
  }
}

.u-mr0\@screen {
  @include screen() {
    margin-right: 0 !important;
  }
}

.u-m0\@screen {
  @include screen() {
    margin: 0 !important;
  }
}

.u-mb0\@tablet {
  @include tablet() {
    margin-bottom: 0 !important;
  }
}

.u-mt0\@tablet {
  @include tablet() {
    margin-top: 0 !important;
  }
}

.u-ml0\@tablet {
  @include tablet() {
    margin-left: 0 !important;
  }
}

.u-mr0\@tablet {
  @include tablet() {
    margin-right: 0 !important;
  }
}

.u-m0\@tablet {
  @include tablet() {
    margin: 0 !important;
  }
}

.u-m025 {
  margin: $spacing-xxs !important;
}

.u-mt025 {
  margin-top: $spacing-xxs !important;
}

.u-mr025 {
  margin-right: $spacing-xxs !important;
}

.u-mb025 {
  margin-bottom: $spacing-xxs !important;
}

.u-ml025 {
  margin-left: $spacing-xxs !important;
}

.u-m05 {
  margin: $spacing-xs !important;
}

.u-mt05 {
  margin-top: $spacing-xs !important;
}

.u-mr05 {
  margin-right: $spacing-xs !important;
}

.u-mb05 {
  margin-bottom: $spacing-xs !important;
}

.u-ml05 {
  margin-left: $spacing-xs !important;
}

.u-m1 {
  margin: $spacing-s !important;
}

.u-mt1 {
  margin-top: $spacing-s !important;
}

.u-mr1 {
  margin-right: $spacing-s !important;
}

.u-mb1 {
  margin-bottom: $spacing-s !important;
}

.u-ml1 {
  margin-left: $spacing-s !important;
}

.u-m15 {
  margin: $spacing-m !important;
}

.u-mt15 {
  margin-top: $spacing-m !important;
}

.u-mr15 {
  margin-right: $spacing-m !important;
}

.u-mb15 {
  margin-bottom: $spacing-m !important;
}

.u-ml15 {
  margin-left: $spacing-m !important;
}

.u-m2 {
  margin: $spacing-l !important;
}

.u-mt2 {
  margin-top: $spacing-l !important;
}

.u-mb2 {
  margin-bottom: $spacing-l !important;
}

.u-ml2 {
  margin-left: $spacing-l !important;
}

.u-mr2 {
  margin-right: $spacing-l !important;
}

.u-m3 {
  margin: $spacing-ll !important;
}

.u-mt3 {
  margin-top: $spacing-ll !important;
}

.u-mr3 {
  margin-right: $spacing-ll !important;
}

.u-mb3 {
  margin-bottom: $spacing-ll !important;
}

.u-ml3 {
  margin-left: $spacing-ll !important;
}


.u-m4 {
  margin: $spacing-xl !important;
}

.u-mt4 {
  margin-top: $spacing-xl !important;
}

.u-mr4 {
  margin-right: $spacing-xl !important;
}

.u-mb4 {
  margin-bottom: $spacing-xl !important;
}

.u-ml4 {
  margin-left: $spacing-xl !important;
}

.u-rowgap0 {
  row-gap: 0 !important;
}



//  ===========================  Padding utils  =========================== 

.u-p0 {
  padding: 0 !important;
}

.u-pt0 {
  padding-top: 0 !important;
}

.u-pr0 {
  padding-right: 0 !important;
}

.u-pb0 {
  padding-bottom: 0 !important;
}

.u-pl0 {
  padding-left: 0 !important;
}

.u-p025 {
  padding: $spacing-xxs !important;
}

.u-pt025 {
  padding-top: $spacing-xxs !important;
}

.u-pr025 {
  padding-right: $spacing-xxs !important;
}

.u-pb025 {
  padding-bottom: $spacing-xxs !important;
}

.u-pl025 {
  padding-left: $spacing-xxs !important;
}

.u-p05 {
  padding: $spacing-xs !important;
}

.u-pr05 {
  padding-right: $spacing-xs !important;
}

.u-pl05 {
  padding-left: $spacing-xs !important;
}

.u-pt05 {
  padding-top: $spacing-xs !important;
}

.u-pb05 {
  padding-bottom: $spacing-xs !important;
}

.u-p1 {
  padding: $spacing-s !important;
}

.u-pt1 {
  padding-top: $spacing-s !important;
}

.u-pr1 {
  padding-right: $spacing-s !important;
}

.u-pb1 {
  padding-bottom: $spacing-s !important;
}

.u-pl1 {
  padding-left: $spacing-s !important;
}

.u-pl0\@MScreenM {
  @include minScreenMedium {
    padding-left: 0 !important;
  }
}

.u-p2 {
  padding: $spacing-l !important;
}

.u-pt2 {
  padding-top: $spacing-l !important;
}

.u-pr2 {
  padding-right: $spacing-l !important;
}

.u-pb2 {
  padding-bottom: $spacing-l !important;
}

.u-pl2 {
  padding-left: $spacing-l !important;
}

.u-p3 {
  padding: $spacing-ll !important;
}

.u-pt3 {
  padding-top: $spacing-ll !important;
}

.u-pr3 {
  padding-right: $spacing-ll !important;
}

.u-pb3 {
  padding-bottom: $spacing-ll !important;
}

.u-pl3 {
  padding-left: $spacing-ll !important;
}

.u-p4 {
  padding: $spacing-xl !important;
}

.u-pt4 {
  padding-top: $spacing-xl !important;
}

.u-pr4 {
  padding-right: $spacing-xl !important;
}

.u-pb4 {
  padding-bottom: $spacing-xl !important;
}

.u-pl4 {
  padding-left: $spacing-xl !important;
}

//  =========================== Show/Hide  =========================== 

//desktop ---SHOW---> mobile
.u-show\@screenM {
  @include screenMedium() {
    display: block;
  }
  display: none;
}

.u-show\@screen {
  @include screen() {
    display: block;
  }
  display: none;
}

.u-show\@tablet {
  @include tablet() {
    display: block;
  }
  display: none;
}

.u-show\@phone {
  @include phone() {
    display: initial !important;
  }
  display: none !important;
}

//desktop ---HIDE---> mobile
.u-hide\@screenM {
  @include screenMedium() {
    display: none !important;
  }
}

.u-hide\@screen {
  @include screen() {
    display: none !important;
  }
}

.u-hide\@tablet {
  @include tablet() {
    display: none !important;
  }
}

.u-hide\@phone {
  @include phone() {
    display: none !important;
  }
}


.u-display-none {
  display: none !important;
}

.u-visibility-hidden {
  visibility: hidden !important;
}

/* =========================== background-color utils ===========================  */

.u-bg-blue-500 {
  background-color: $color-blue-500;
}

.u-bg-blue-100 {
  background-color: $color-blue-100;
}

.u-bg-indigo-900 {
  background-color: $color-indigo-900;
}

.u-bg-grey-100 {
  background-color: $color-grey-100;
}

.u-bg-grey-300 {
  background-color: $color-grey-300;
}

.u-bg-white {
  background-color: $color-white;
}

.u-bg-transparent {
  background-color: transparent;
}

/* ===========================  Borders ===========================  */

.u-vertical-line {
  display: inline;
  border-left: 1px solid $color-grey-900;
}

.u-b0 {
  border: 0 !important;
}

.u-bt0 {
  border-top: 0 !important;
}

.u-br0 {
  border-right: 0 !important;
}

.u-bl0 {
  border-left: 0 !important;
}

.u-bb0 {
  border-bottom: 0 !important;
}

.u-b1 {
  border: 1px solid $color-grey-500 !important;
}

.u-bt1 {
  border-top: 1px solid $color-grey-500 !important;
}

.u-br1 {
  border-right: 1px solid $color-grey-500  !important;
}

.u-bl1 {
  border-left: 1px solid $color-grey-500  !important;
}

.u-bb1 {
  border-bottom: 1px solid $color-grey-500 !important;
}


//  =========================== Positioning =========================== 
.u-float-right {
  float: right !important;
}

.u-float-left {
  float: left !important;
}

.u-float-none {
  float: none !important;
}

/* display-inline class to allow for example action links next to titles */
.u-display-inline {
  display: inline-block !important;
}

/* display-block class helps angular DOM components to act regulary so they dont mess up other css */
.u-display-block {
  display: block !important;
}

.u-display-flex {
  @include flexbox();
}

.u-jc-center {
  @include flexbox();
  @include justify-content(center);
}

.u-ai-center {
  @include flexbox();
  @include align-items(center);
}

.u-ai-stretch {
  @include flexbox();
  @include align-items(stretch);
}

.u-ai-start {
  @include flexbox();
  @include align-items(start);
}

.u-jc-ai-center {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.u-flex-basis-100 {
  @include flexbox();

  flex-basis: 100% !important;
}

.u-display-flex\@min-phone {
  @include minPhone() {
    display: flex !important;
  }
  display: none !important;
}

//  =========================== Align Self =========================== 
.u-align-self-start {
  align-self: start !important;
}

.u-align-self-center {
  align-self: center !important;
}

.u-align-self-end {
  align-self: end !important;
}

.u-full-width {
  width: 100% !important;
}

//  =========================== Text formatting =========================== 
.u-pre-line {
  white-space: pre-line;
}

.u-text-nowrap {
  white-space: nowrap;
}

.u-text-prewrap {
  white-space: pre-wrap;
}

.u-text-small {
  font-size: .875rem;
}

.u-text-normal {
  font-size: $font-size-base;
  font-weight: 400;
}

.u-font-weight-strong {
  font-weight: 500;
}

.u-text-meta {
  color: $color-grey-700;
}

.u-text-caps {
  text-transform: uppercase;
}

.u-reset-list {
  @include reset-list();
}
.u-break-word {
  word-break: break-word;
}
.u-break-all {
  word-break: break-all;
}

ol.-compact, ul.-compact {
  li {
    margin: 0; //dumb ass overrule for regular list
    padding-top: calc(#{$baseline}/4);
    padding-bottom: calc(#{$baseline}/4);
  }
}

/* font-color */
.u-color-green {
  color: $color-lightgreen-600;
}

.u-text-color-orange {
  color: $color-orange-a500;
}

.u-text-color-turquoise {
  color: $color-turquoise-500;
}

.u-text-color-indigo {
  color: $color-indigo-900;
}

.u-text-color-red {
  color: $color-red-700;
}

.u-text-color-white {
  color: $color-white;
}

.u-text-color-green {
  color: $color-success;
}

/*===========================Other===========================*/ 

.u-space-between { //DEPRECATED, do not use
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
}

.u-focus:focus {
  outline: 2px solid $color-blue-400;
}

.u-hs-overlay {
  position: fixed;
  z-index: 120;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize($color-grey-a500, .5);
}

/*often used in conjunction with the overlay, to be place on the body*/
.u-no-scroll {
  overflow: hidden;
}

.u-sr-only {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
}

.u-disabled {
  cursor: not-allowed !important;
  text-decoration: none !important;
  color: $color-grey-700 !important;

  &:hover {
    text-decoration: none !important;
    color: $color-grey-700 !important;
  }

  &:visited {
    color: $color-grey-700 !important;
  }

  &:focus {
    outline: 0 !important;
  }
}

.u-text-decoration-none {
  text-decoration: none !important;
}

.u-vertical-align-b {
  vertical-align: bottom !important;
}


//============================================================================================================
//TO DELETE AFTER DRUPAL CHANGES
//============================================================================================================

.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.m025 {
  margin: $spacing-xxs !important;
}

.mt025 {
  margin-top: $spacing-xxs !important;
}

.mr025 {
  margin-right: $spacing-xxs !important;
}

.mb025 {
  margin-bottom: $spacing-xxs !important;
}

.ml025 {
  margin-left: $spacing-xxs !important;
}

.m05 {
  margin: $spacing-xs !important;
}

.mt05 {
  margin-top: $spacing-xs !important;
}

.mr05 {
  margin-right: $spacing-xs !important;
}

.mb05 {
  margin-bottom: $spacing-xs !important;
}

.ml05 {
  margin-left: $spacing-xs !important;
}

.m1 {
  margin: $spacing-s !important;
}

.mt1 {
  margin-top: $spacing-s !important;
}

.mr1 {
  margin-right: $spacing-s !important;
}

.mb1 {
  margin-bottom: $spacing-s !important;
}

.ml1 {
  margin-left: $spacing-s !important;
}

.m15 {
  margin: $spacing-m !important;
}

.mt15 {
  margin-top: $spacing-m !important;
}

.mr15 {
  margin-right: $spacing-m !important;
}

.mb15 {
  margin-bottom: $spacing-m !important;
}

.ml15 {
  margin-left: $spacing-m !important;

}

.mt05- {
  margin-top: -$spacing-xs !important;
}

.m1- {
  margin: -$spacing-s !important;
}

.mt1- {
  margin-top: -$spacing-s !important;
}

.mr1- {
  margin-right: -$spacing-s !important;
}

.mb1- {
  margin-bottom: -$spacing-s !important;
}

.ml1- {
  margin-left: -$spacing-s !important;
}


.p0 {
  padding: 0 !important;
}

.p025 {
  padding: $spacing-xxs !important;
}

.pt025 {
  padding-top: $spacing-xxs !important;
}

.pr025 {
  padding-right: $spacing-xxs !important;
}

.pb025 {
  padding-bottom: $spacing-xxs !important;
}

.pl025 {
  padding-left: $spacing-xxs !important;
}

.p05 {
  padding: $spacing-xs !important;
}

.p1 {
  padding: $spacing-s !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr05 {
  padding-right: $spacing-xs !important;
}

.pl05 {
  padding-left: $spacing-xs !important;
}

.pt05 {
  padding-top: $spacing-xs !important;
}

.pt1 {
  padding-top: $spacing-s !important;
}

.pr1 {
  padding-right: $spacing-s !important;
}

.pb1 {
  padding-bottom: $spacing-s !important;
}

.pb05 {
  padding-bottom: $spacing-xs !important;
}

.pl1 {
  padding-left: $spacing-s !important;
}

.bg-indigo-900 {
  background-color: $color-indigo-900;
}

.bg-grey-100 {
  background-color: $color-grey-100;
}




/* Other */

.vertical-line {
  display: inline;
  border-left: 1px solid $color-grey-900;
}

.display-none {
  display: none !important;
}


/* Darkend background overlay
* Use: add to a wrapper div
*/

.hs-overlay {
  position: fixed;
  z-index: 120;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize($color-grey-a500, .5);
}


.bg-blue-100 {
  background-color: $color-blue-100;
}

.bg-white {
  background-color: $color-white;
}

.bg-blue-500 {
  background-color: $color-blue-500;
}

.mb0 {
  margin-bottom: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

// Floatings
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none !important;
}

.align-self-start {
  align-self: start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.margin-left-auto {
  margin-left: auto;
}

.full-width {
  width: 100% !important;
}

// END to TO DELETE AFTER DRUPAL CHANGES