.c-vacatures-widget,
.c-vacatures {
  // legacy
  &__slat {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
    position: relative;

    &.-closed {
      .c-vacatures-widget__vacstats {
        color: $color-grey-500;
      }
    }
  }

  &__vacstats {
    @include flexbox();
    @include align-self(center);
    @include justify-content(flex-end);
    flex-wrap: nowrap;
    margin-left: $spacing-m;
    flex-grow: 1;
    gap: $spacing-s;
  }

  &__stat-container {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    flex-wrap: nowrap;
    margin: 0;
    flex-grow: 1;
    flex-basis: 0;
  }

  &__stat-number {
    font-size: $font-size-base * 2.5;
    font-weight: 500;
    line-height: 100%;
    position: relative;
  }

  &__stat-label {
    font-size: .875rem;
  }

  &__stat-notification {
    position: absolute;
    top: 0;
    display: inline-block;
    width: .75rem;
    height: .75rem;
    border-radius: 100%;
    background-color: $color-burgundy-500;
  }

  &__slat-title {
    position: relative;
    width: 100%;

    align-self: center;
    flex-basis: 65%;
  }

  &__slat-status {
    font-size: $text-small;
    text-transform: uppercase;
  }

  &__slat-status-extra {
    display: inline-block;
    margin-left: .5rem;

    &:before {
      display: inline-block;
      width: .4rem;
      height: .4rem;
      margin-right: .5rem;
      content: ' ';
      vertical-align: middle;
      border-radius: 100%;
      background-color: $color-grey-900;
    }

    &.-date {
      color: $text-meta;

      &:before {
        background-color: $text-meta;
      }
    }

    &.-warning {
      color: $color-danger;

      &:before {
        background-color: $color-danger;
      }
    }
  }

  &__VDAB-beheer {
    vertical-align: middle;
  }

  &__alert {
    display: inline-block;
    margin-top: $spacing-xs;
    padding: $spacing-xxs $spacing-xs;
    border-radius: 7px;

    &.-info {
      color: $color-blue-700;
      border: 1px solid transparentize($color: $color-blue-700, $amount: .85);
      background-color: $color-blue-100;
    }

    &.-warning {
      color: $color-orange-700;
      border: 1px solid transparentize($color: $color-orange-700, $amount: .85);
      background-color: $color-orange-100;
    }

    &.-danger {
      color: $color-danger;
      border: 1px solid transparentize($color: $color-danger, $amount: .85);
      background-color: $color-red-100;
    }
  }

  &__alert-text {
    margin-right: .5rem;
  }

  &__alert-link {
    font-weight: 500;
    display: inline-block;
    color: inherit;
    text-decoration-color: inherit;

    &:visited {
      color: inherit;
      text-decoration-color: inherit;
    }

    &:hover {
      text-decoration-color: inherit;

      .-info & {
        color: darken($color-blue-700, 15%);
      }
  
      .-warning & {
        color: darken($color-orange-700, 15%);
      }
  
      .-danger & {
        color: darken($color-danger, 15%);
      }
    }
  }

  .c-empty-state-vacatures {
    background-image: url('https://cdn.vdab.be/img/components/cta-block/Vacature-horizontaal.svg');
  }

  .c-dropdown__menu {
    // override
    width: auto;
    max-width: 90vw; //to prevent it going off screen on small screens
  }
}


@include tablet() {
  .c-vacatures-widget,
  .c-vacatures {
    // legacy
    &__slat-status {
      display: inline-block;
      max-width: 90%;
    }

    &__vacstats {
      @include flex-direction(row);
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-top: $spacing-s;
      margin-left: 0;
    }

    &__stat-number {
      display: inline-block;
    }

    &__stat-label {
      display: inline-block;
    }
    
    &__stat-notification {
      width: .5rem;
      height: .5rem;
    }

    &__slat-title {
      @include align-self(flex-start);
    }
  }
}

@include phone() {
  .c-vacatures-widget,
  .c-vacatures {
    &__vacstats {
      gap: 0;
    }

    &__vacstats > :first-child:nth-last-child(4),
    &__vacstats > :first-child:nth-last-child(4) ~ * {
      flex-basis: 50%;
    }

    &__slat-status-extra {
      margin-left: 0;
      
      &:before {
        display: none;
      }
    }
  }
}

@include screen() {
  .c-vacatures-widget,
  .c-vacatures {

    .c-empty-state-vacatures {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/Vacatures.svg');
    }
  }
}