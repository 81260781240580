.c-navbar-consultants {
  @include flexbox;
  @include justify-content(space-between);
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 !important;

  &__vdab-logo {
    padding: $spacing-s $spacing-s $spacing-s 0;
  }
  
  &__vdab-logo-img {
    height: 24px;
    vertical-align: middle;
  }

  //sass-lint:disable-all

  &__item {
    @include flexbox;
    border-left: 1px solid rgba($color-blue-300, .25); //sass-lint:disable-line
    &:hover {
      background-color: rgba($color-blue-300, .25); //sass-lint:disable-line
    }
    &:last-child {
      border-right: 1px solid rgba($color-blue-300, .25); //sass-lint:disable-line
    }

    //sass-lint:enable-all


    &.c-dropdown {
      @include flexbox();
    }
  }

  &__item-link {
    @include flexbox;
    @include align-items(center);
    padding: 0 $spacing-s;
    text-decoration: none;
    word-break: break-word;
    color: $color-white !important; // fix for weird behavior where in specific instances this link on the left next to the logo dissapears (or becomes blue)

    &.icon-user:before {
      margin-right: $spacing-xs;
    }

    &:hover {
      text-decoration: none;
      color: $color-white !important;
    }
    &:visited {
      color: $color-white;
    }
  }

  &__search-input {
    font-size: 14px;
    display: block;
    width: 170px;
    height: 30px;
    padding: 0 1rem;
    border: 0 !important;
    border-radius: 50px 0 0 50px !important;
    background: $color-white;
    box-shadow: unset !important;

    align-self: center !important;

    &:focus {
      border: 1px solid $color-indigo-900 !important;
    }

  }

  &__search-btn {
    font-size: 14px;
    display: block;
    width: 30px;
    height: 30px;
    transition: all .1s;
    color: $color-grey-900;
    border: 0;
    border-radius: 0 50px 50px 0;
    background: $color-white;

    &:focus {
      outline: $color-blue-400 solid 2px;
    }
  }

}

@include screen {
  .c-navbar-consultants {
    &__item-link {
      &.icon-user:before {
        margin-right: 0;
      }  
    }
  }
}

/* subnavbar
---------------------------------------------------------------------*/

.c-subnavbar-application {
  border-bottom: 1px solid $color-grey-500;
  background: $color-white;
}

.c-subnavbar-application .c-subnavbar-application__wrapper {
  margin: 0 auto;

  .subnavbar-items {
    padding: 0;
    list-style: none;
  }

  .subnavbar-item {
    display: inline-block;
    margin-right: 2rem;
  }

  .subnavbar-link {
    text-decoration: none;
    color: $color-grey-900;
    &:hover {
      text-decoration: none;
      color: $color-blue-a500;
    }
  }

}


/* Footer
---------------------------------------------------------------------*/
.c-footer-application {
  padding: 1rem 0;
  background: $color-grey-300;

  .c-footer-application__container {
    margin: 0 auto;
  }

  a {
    text-decoration: none;
    color: $transparent-black-50;
  }

  a:hover {
    color: $color-grey-900;
  }
}

// Sticky flex footer that moves with content

body, .flex-footer-application { // for angular apps where it is impossible to put head, main and footer directly under body you can use a special class
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container-footer-application {
  flex: 1;
}
