.c-competentiecheck-widget {

  &__label {
    font-size: $font-size-base;
    font-weight: 500;
    color: $color-grey-900;

  }
  &__select {
    @include flexbox();
    @include align-items(center);
    @include tablet() {
      @include flex-direction(column);
      @include align-items(flex-start);

      .form-field.field-select {
        width: 100%;
      }
    
    }

    gap: $spacing-xs;
  }

  &__card {
    padding: $spacing-s;

    .c-card__title {
      margin-bottom: $spacing-xs; 

      .o-routing-link {
        font-size: $font-size-h4;
      }
    }
  }


}