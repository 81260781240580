.c-orientation-widget {
  position: relative;
  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  padding-bottom: ($spacing-l+$spacing-xs) - 1rem;
  border-radius: $spacing-xs;
  background-color: $color-blue-100;


  &.has-error {
    border: 1px solid $color-grey-500;
    background-color: $color-white;

    .c-orientation-widget__icon {
      color: $color-grey-500;
    }
  }

  &.-empty {
    @include flexbox();
    @include screen() {
      @include flex-direction(column);

      gap: $spacing-m;
    }

    gap: $spacing-l;
  }

  &__content-container {
    margin-bottom: 1rem;
    margin-left: 4rem;

    .-empty & {
      margin: 0;
    }
  }
  
  &__title {
    position: relative;
    //border-bottom: 1px solid $color-grey-500;
    margin-top: 0;
    margin-bottom: $spacing-xs;
    margin-left: 4rem;
    padding-bottom: 0;

    .-empty & {
      margin-left: 0;
    }
  }

  &__image {
    position: absolute;
    top: -111px;
    right: 5px;
    display: none;
    width: 150px;
    text-align: right;
  }

  &__icon {
    font-size: 42px;
    position: absolute;
    top: 45px;
    color: $color-indigo-900;

    .-empty & {
      position: unset;
      top: unset;
    }
  }

  &__content {
    margin-bottom: 1rem;
    margin-left: 4rem;
  }



  &__btn-group {
    @include flexbox;
    @include justify-content(space-between);
    @include flex-wrap(wrap);
  }

}

@include tablet() {
  .c-orientation-widget__container {
    grid-gap: 8rem !important;
  }
}

@include phone() {
  .c-orientation-widget__container {
    grid-gap: 8rem !important;
  }
  .c-orientation-widget {
    &__title {
      margin-left: 0;
    }

    &__icon {
      font-size: 32px;
      position: relative;
      top: 0;
      margin-bottom: $spacing-s;
    }
    &__content {
      margin-left: 0;
    }
  }
}