.c-footer {
  color: $color-white;
  background: $color-blue-900;

  .o-wrapper {
    margin-top: 0 !important;
  }

  &__btn {
    &:disabled {
      cursor: not-allowed;
      color: $transparent-white-75 !important;
      border: 1px solid $transparent-white-25 !important;
      background-color: $color-blue-900 !important;

      &:before {
        color: transparentize($color-white, .85);
      }
    }
  }
  // general sstuff
  &__link {
    color: $color-white;

    text-decoration-color: $color-white;


    &:active {
      color: darken($color-white, 15%);

      text-decoration-color: $color-white;
    }

    &:visited {
      color: $color-white;

      text-decoration-color: $color-white;
    }

    &:hover {
      color: darken($color-white, 15%);

      text-decoration-color: $color-white;
    }

    &.-breadcrumb-link {
      margin-bottom: 0;
    }
  }

  // breadcrumb
  &__breadcrumb {
    @include flexbox();
    @include align-items(center);
    @include flex-wrap(wrap);
    margin-bottom: 2rem;
    padding: 0;
    padding-top: 4rem;
    list-style: none;

    &__item {
      @extend .icon-crumb-arrow-right;
      color: $color-white;

      &::before {
        margin: 0 25px 0 30px;
      }
    }


  }

  // left side of footer
  &__link-container {
    @include display-grid;
  }


  &__button-group {
    @include grid-align-self(end);

    -ms-grid-row: 2;

    &__button {
      @include phone {
        display: block;
        text-align: center;
      }
      line-height: 1.562rem;
      margin-right: 16px;
      margin-bottom: 0;
      padding: .313rem .938rem;
      text-decoration: none;
      color: $color-white;
      border: .063rem solid $color-blue-500;
      border-radius: .321rem;
      background-color: $color-blue-900;

      &:visited {
        text-decoration: none;
        color: $color-white;
        background-color: $color-blue-900;

      }

      &:focus {
        text-decoration: none;
        background-color: $color-indigo-900;

      }

      &:hover {
        text-decoration: none;
        color: $color-white;
        background-color: $color-blue-700;

      }
    }

  }

  //right side of footer
  &__contact {
    padding-left: 32px;
    border-left: 1px solid $transparent-white-25;

    &__title {
      margin-top: 0;
      color: $color-white;
    }

    &__text {
      margin-top: $spacing-m;
      margin-bottom: $spacing-s;
    }

    &__social {
      @include flexbox();

      &__item {
        margin: 0;

        &:not(:first-child) {
          margin-left: $spacing-s;
        }
      }
    }
  }

  //flanders footer
  &__flanders {
    overflow: hidden;
    color: $color-black;
    background: $color-white;

    &__container {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;

    }

    &__backslash-before {
      &::before {
        position: absolute;
        bottom: -1px;
        left: 1rem;
        display: inline-block;
        width: 1px;
        height: 46px;
        content: '';
        transform: rotate(-20deg);
        background: $color-grey;
      }

      &.-logo-backslash {
        &::before {
          left: 2rem;
        }
      }

    }

    &__link {
      font-weight: 400;
      position: relative;
      overflow: hidden;
      padding-top: .5rem;
      padding-bottom: .5rem;
      padding-left: 2.2rem;
      text-decoration: none;
      color: $color-black;
      border-right: 0;

      &:visited {
        text-decoration: none;
        color: $color-black;
      }

      &:hover {
        color: darken($color-blue-500, 15%);
      }

      &.-logo-link {
        padding-left: 3rem;
        background: $color-white url('https://cdn.vdab.be/img/logos/vvm-logo-mark-dark.svg') center left no-repeat;
      }
    }

    &__language {
      @include flexbox();
      position: relative;
      padding-left: 2.2rem;

      &__item {
        display: inline;
        margin: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;


      }
    }

  }
}

@include screen() {
  .c-footer {
    &__link {
      width: 100%;
    }

    &__link-container-column {
      width: 100%;
      padding-bottom: $spacing-l;

      &:not(:last-child) {
        border-bottom: 1px solid $transparent-white-15;
      }
    }

    &__button-group {
      padding-top: $spacing-m;
      padding-bottom: $spacing-m;
      border-top: 1px solid $transparent-white-15;
      border-bottom: 1px solid $transparent-white-15;

      grid-column: 1/4;

      &__button {
        display: block;
        text-align: center;

        &:not(:first-child) {
          margin-top: $spacing-m;
        }
      }
    }

    &__contact {
      margin-top: $spacing-m;
      padding: 0;
      border: 0;
    }

    &__flanders {
      &__container {
        @include flex-wrap(wrap-reverse);
        padding-left: 0;
      }

      &__backslash-before {
        &::before {
          display: none;
        }
      }

      &__link {
        padding-left: 2rem;

        &:first-child {
          padding-left: 0;
        }
        
        &.-logo-link {
          width: 100%;
          padding-left: 0;
          background: none;
        }
      }

      &__language {
        &__item {
          margin-right: $spacing-s;
        }
      }

    }
  }
}

@include tablet {
  .c-footer__flanders__language {
    padding-left: 0;
  }
  .c-footer__flanders__link {
    padding-left: 0;
  }
  .c-footer__flanders__backslash-before {
    padding-right: $spacing-l;
  }
}

.icon-crumb-arrow-right {
  @extend .icon;
  @extend .icon-arrow-right;

}


