@include tablet() {
  .c-sub-navbar {
    &__menu {
      display: block;
      width: 100%;
    }

    &__menu-list {
      @include reset-list();
      @include flexbox(inline-flex);
      width: 100%;
      border: $color-blue-300 1px solid;
      border-radius: 5px;
      background-color: $color-white;
    }

    &__menu-item {
      @include flexbox();
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      border-right: $color-blue-300 1px solid;

      &:hover {
        color: $color-blue-700;
        background: $color-blue-100;
      }

      &.-active[aria-current='true'] {
        background-color: $color-blue-200;
      }

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      &:last-child {
        border: 0;
      }
    }

    &__menu-link {
      width: 100%;
      padding: .5rem 1rem;
      text-align: center;
      text-decoration: none;
      color: $color-blue;

      &:visited {
        color: $color-blue;
      }

      &:hover {
        color: $color-blue-900;
      }

      .-active & {
        font-weight: 500;
        color: $color-blue-900;
      }
    }
  }
}
