.c-donut-chart-container {
  @include flexbox();
  @include justify-content(center);
  @include flex-direction(column);
  text-align: center;
  color: $color-grey-900;
}

.c-donut-chart {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 7rem;
  height: 4.25rem;
  color: var(--fill);
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 7rem;
    height: 7rem;
    content: '';
    transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
    animation: fillAnimation 1s ease-in;
    border: .8rem solid;
    border-color: $color-grey-300 $color-grey-300 var(--fill) var(--fill);
    border-radius: 50%;
  }

  &.-no-animation {
    &:after {
      animation: unset;
    }
  }

  &.-white:after {
    border-color: $color-white $color-white var(--fill) var(--fill);
  }

  @keyframes fillAnimation {
    0% {
      transform: rotate(-45deg);
    }
    50% {
      transform: rotate(135deg);
    }
  }

  @keyframes fillGraphAnimation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
  }

  &__percentage {
    font-size: $font-size-h3;
    margin-top: -2.5rem;
  }
}
