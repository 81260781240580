.c-vewn,
.c-vewn-widget {

  // .c-vewn is legacy
  &__wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__widget {
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }
}

@include screen() {

  .c-vewn,
  .c-vewn-widget {

    // .c-vewn is legacy
    &__wrapper {
      margin-bottom: $spacing-xl;
    }

    &__widget {
      transform: none;

      & .c-search-widget__submit-button.has-loading__box {
        width: 100%;
      }
    }
  }
}