$gap: $spacing-m;

.c-snackbar {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  position: relative;
  overflow: hidden;
  max-height: 0;
  margin-top: 0;
  padding: 0 $spacing-xs 0 $spacing-s;
  transition-timing-function: ease-in;
  transition-duration: 150ms;
  transition-property: max-height, opacity, padding, margin-top;
  pointer-events: auto;
  opacity: 0;
  border-radius: 5px;
  
  // MODIFIERS 

  &.-neutral {
    color: $color-white;
    background-color: $color-grey-900;

    .c-snackbar__close {
      color: $color-white;
      border-left-color: $color-white;
      &:hover, &:active, &:focus {
        color: $color-white;
        background-color: transparent;
      }
      &:focus {
        color: $color-white;
        border-color: transparent;
        border-right: 1px solid transparent;
        border-left: 1px solid;
        border-left-color: $color-white;
      }
    }
  }

  &.-warning {
    color: $color-orange-900;
    background-color: $color-orange-300;

    .c-snackbar__close {
      color: $color-orange-900;
      border-left-color: $color-orange-900;

      &:hover, &:active, &:focus {
        color: $color-orange-900;
        background-color: transparent;
      }
      &:focus {
        color: $color-orange-900;
        border-color: transparent;
        border-right: 1px solid transparent;
        border-left: 1px solid;
        border-left-color: $color-orange-900;
      }
    }

    .c-snackbar__action {
      color: $color-orange-900;

      text-decoration-color: $color-orange-900;

      &:visited {
        color: inherit;

        text-decoration-color: inherit;
      }

      &:hover {
        color: inherit;

        text-decoration-color: inherit;
      }
    }
  }

  &.-light {
    padding-right: $spacing-s;
    color: $color-grey-900;
    background-color: $color-grey-200;

    justify-self: flex-start;

    // .c-icon-text__icon.-cycle {
    //   animation: spin 1.5s linear 0s infinite normal;
    // }

    .c-snackbar__content {
      @include align-items(center);
      margin-right: 0;
    }

    &.is-shown {
      padding-right: $spacing-s;
    }
  }

  &.is-shown {
    max-height: 500px;
    margin-top: $gap;
    padding: $spacing-s $spacing-xs $spacing-s $spacing-s;
    transition-timing-function: ease-in;
    transition-duration: 150ms;
    transition-property: max-height, opacity, padding, margin-top;
    opacity: 1;
  }

  // styling elements 

  &__content {
    max-width: 400px;
    margin-right: $spacing-m;
    margin-bottom: 0;
  }

  &__actions {
    @include flexbox();
  }

  .c-snackbar__action {
    @include align-self(center);
    font-weight: 500;
    margin: 0 $spacing-s;
    color: $color-white;

    text-decoration-color: $color-white;

    &:visited {
      color: inherit;

      text-decoration-color: inherit;
    }

    &:hover {
      color: inherit;

      text-decoration-color: inherit;
    }
  }


  .c-snackbar__close {
    @include align-self(center);
    padding-right: 0;
    padding-left: $spacing-xs;
    text-align: center;
    color: $color-white;
    border-left: 1px solid;
    border-left-color: $color-white;
    border-radius: 0;
    &:hover, &:active, &:focus {
      color: $color-white;
      background-color: transparent;
    }
    &:focus {
      color: $color-white;
      border: 0;
      border-left: 1px solid;
      border-left-color: $color-white;
    }
  }

  &__close-icon {
    width: 19px;
    height: 19px;

    fill: $color-white;

    .-warning & {
      fill: $color-orange-900;
    }
  }

  &__container {
    @include display-grid;
    @include ie-grid-items-2();
    position: fixed;
    bottom: 24px;
    left: 24px;
    margin-right: $spacing-s;
    pointer-events: none;
  }
}

@include tablet() {
  .c-snackbar {
    @include flex-direction(column);
    @include align-items(start);
    &.is-shown {
      margin-top: $spacing-xs;
    }
    &__actions {
      @include flexbox();
      @include justify-content(space-between);
      width: 100%;
    }
    .c-snackbar__action {
      margin-right: $spacing-l;
      margin-left: 0;
    }
    .c-snackbar__close {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 100%;
      padding-left: 0;
    }
    &__content {
      margin-right: $spacing-l;
    }
    &__container {
      right: 24px;
      width: calc(100% - 48px);
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


