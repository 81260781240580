.c-search-filter {

  &.-mobile {
    display: none;
  }

  &__title {
    font-size: $font-size-base;
    font-weight: 400;
    margin-bottom: 1rem;
    color: $color-grey-600;
  }

  &__container {
    padding: 2rem;
    border-radius: 5px;
    background-color: $color-blue-100;
  }

  &__header-link {
    font-size: $font-size-h4;
    font-weight: 500;
    display: block;
    width: 100%;
    color: $color-indigo-900;
  } 

  &__accordion {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $color-grey-500;
  }

  &__accordion:last-child {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  &__header-link::after {
    font-size: 1rem;
    float: right;
    margin-top: .3rem;
    color: $color-grey-900;
  } 

 
  &__list-item {
    margin-top: 0;
    margin-bottom: 0;
  }
 
  &__list-item:first-child {
    margin-top: .5rem;
    margin-bottom: 0;
  }
  
  &__sub-list-item {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  
} 

@include tablet() {
  .c-search-filter {
    display: none;
    
    &.-mobile {
      position: sticky;
      z-index: 1;
      top: 0;
      display: block;
      width: calc(100% + 3rem);
      margin-right: -1.5rem;
      margin-left: -1.5rem;
      padding: $spacing-m;
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid $color-indigo-900;
      border-left: 0;
      background-color: $color-blue-100;
    }

    &__container {
      .-mobile & {
        padding: 0;
      }

      &.-collapsed {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(flex-start);
        text-decoration: none;
        color: inherit;

      }
    }
  }
}