.c-contact-cta {
  @include flexbox();
  font-size: $font-size-h3;
  margin-bottom: $spacing-l;

  &__pre-icon {
    font-size: $font-size-h3;
    margin-right: $spacing-m;
    color: $color-grey-700;
  }

}