.faq-block {

  &:not(:last-child) {
    margin-bottom: $spacing-m;
  }

  .expand-all-panels {
    display: grid;
    justify-content: end;
    padding-bottom: .8rem;
    border-bottom: 1px solid $color-grey-600;
  }

  .panel-block {
    display: flex;
    justify-content: flex-start;
    padding-top: .8rem;
    padding-bottom: .14rem;
    border-bottom: 1px solid $color-grey-600;

    [class*='icon'] {
      margin-right: 16px;
      color: $color-grey-600;
    }
  }


  .panel-head {
    margin-bottom: .66rem;

    a {
      font-weight: 500;
    }
  }

}