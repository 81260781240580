.text-inputfield-styling {
  line-height: 26px;
  box-sizing: border-box;
  padding: 10px $spacing-s;
  border: 1px solid $color-grey-600;
  border-radius: 5px;
  box-shadow: inset 0 2px 4px $transparent-black-10;

  align-self: start;

  &:focus {
    border: 1px solid $color-blue-700;
  }

  &:disabled {
    // Fix for the native opacity change in Safari 
    opacity: 1;
    background-color: $color-grey-200;
  }

}

input[type='text'] {
  @extend .text-inputfield-styling;
  max-width: 100%;
}

input[type='date'] {
  @extend .text-inputfield-styling;
  max-width: 100%;
  background-color: $color-white;

  -webkit-appearance: none;
}

::-webkit-datetime-edit {
  height: 26px;
}

::-webkit-datetime-edit-fields-wrapper {
  color: $color-grey-800;
}

input[type='time'] {
  @extend .text-inputfield-styling;
  background-color: $color-white;

  -webkit-appearance: none;
}

input[type='number'] {
  @extend .text-inputfield-styling;
  max-width: 100%;
}

input[type='search'] {
  @extend .text-inputfield-styling;
  max-width: 100%;
  -webkit-appearance: none;
}

textarea {
  @extend .text-inputfield-styling;
  width: 100%;
}

input[type='email'] {
  @extend .text-inputfield-styling;
  max-width: 100%;
}

.field-currency {
  position: relative;

  input[type='text'] {
    max-width: 100%;
    padding-left: $spacing-l;
  }

  &:before {
    @include flexbox;
    @include align-items(center);
    font-family: $brand-font-regular;
    position: absolute;
    height: 48px;
    padding: 0 $spacing-xs 0 $spacing-s;
    content: '\20AC';
    color: $color-grey-600;
    background: none;

    alt: '';
  }
}

.field-url {
  input[type='url'] {
    @extend .text-inputfield-styling;
    max-width: 100%;
  }
}

.field-percentage {
  input[type='text'] {
    @extend .text-inputfield-styling;
    width: 96px;
  }
}

.field-input-clear {
  input::-ms-clear {
    display: none;
  }
}

.field-search {
  input[type='text'] {
    @extend .text-inputfield-styling;
  }
}

.form-field-icon {
  @include flexbox;
  max-width: 100%;

  &.external {
    @include align-items(center);
    width: unset;

    .field-icon {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      box-sizing: border-box;
      width: 40px;
      height: 48px;
      margin: 0;
      margin-left: $spacing-xs;
    }
  }

  &:not(.external) {
    input, select, input[type='text'], input[type='password'] {
      padding-right: 3rem;

      &.c-search-widget__small-select {
        padding-right: 2.2rem;
      }
    }
  }

  .field-icon {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    z-index: 1;
    margin: $spacing-xxs 0;
    margin-left: -2.5rem;
    padding: $spacing-xxs;
    cursor: pointer;

    &:before {
      font-size: $font-size-base;
      color: $color-grey-800;
    }

    &:hover, &:focus {
      &:before {
        color: $color-blue-500;
      }
    }

    &.icon-percentage {
      cursor: default;
      pointer-events: none;

      &:before {
        font-family: $brand-font-regular;
        content: '\0025';
      }

      &:hover {
        &:before {
          color: $color-grey-800;
        }
      }
    }

    &.icon-search {
      cursor: default;
      pointer-events: none;

      &:hover {
        &:before {
          color: $color-grey-800;
        }
      }
    }
  }
  .btn {
    margin-left: -51px; 
  }
}

@include tablet() {

  input[type='text'], input[type='email'], input[type='password'], input[type='url'], input[type='search'], textarea, select {
    width: 100%;
  }

  .field-currency {
    input[type='text'] {
      width: unset;
    }

  }
  
}

@include phone() {

  input[type='number'] {
    width: 100%;
  }

  .field-currency {
    input[type='text'] {
      width: 100%;
    }

  }
  
}
