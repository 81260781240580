.c-card-block {
  @include screen() {
    &.-max-4-items\@screen {
      .c-card-block__card:nth-child(n + 5) {
        display: none;
      }
    }
  }

  &__card {
    @include screenMedium {
      @include flex(auto);
    }
  }

  &__cta-section {
    @include flexbox;
    @include flex-direction(column);
  }

  &__show-all {
    @include screen {
      margin-top: $spacing-s;
    }
    display: block;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

// .c-card-block.has-carousel {
//   .c-card-block__cta-section {
//     @include justify-content(flex-start);
//     margin-top: -$gap;
//   }
// }