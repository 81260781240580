.c-content-block {

  h4, .h4 {
    margin-top: calc(27px/2);
    margin-bottom: 11px;

    &:first-child {
      margin-top: 0;
    }
  }
  &:not(:last-child) {
    margin-bottom: $spacing-m;
  }


  &__image-box {
    float: right;
    width: 50%;
    margin-top: 50%;
    margin-right: -20rem;
    margin-left: 4rem;
  }

  &__caption {
    text-align: right;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}