.c-cta-widget {
  position: relative;
  overflow: hidden;
  
  &__title {
    position: relative;
    margin-left: 3.5rem;
    padding-bottom: $spacing-s;
    border-bottom: 1px solid $color-grey-500;
  }

  &__icon {
    font-size: 42px;
    position: absolute;
    top: 23px;
    color: $color-blue-500;

    &.-turquoise {
      color: $color-turquoise-700;
    }
  }

  &__list {
    margin-left: 3.5rem;
    padding: 0;
    list-style-type: none;
  }

  &__list-item {
    @include flexbox();

    &:before {
      @extend .icon;
      @extend .icon-check-outline;
      padding-top: 4px;
      padding-right: $spacing-xs;
      padding-bottom: 2px;
      color: $color-grey-700;
      }
  }

  &__content {
    margin-left: 3.5rem;
  }
}

@include phone() {
  .c-cta-widget {
    &__icon {
      font-size: 28px;
      top: 30px;
    }
    &__title {
      margin-left: 3rem;
    }
    &__list, &__content {
      margin-left: 0;
    }
  }
}