
.in-de-kijker-blok-v2 .c-in-de-kijker-block {
  padding: $spacing-l;
  border: 1px solid $color-orange-900;
  border-radius: .5rem;
  background-color: $color-burgundy-100;

  &__blok-pill {
    line-height: 1.75rem;
    display: inline-block;
    padding: 0 .625rem;
    text-decoration: none;
    color: $color-white;
    border: 1px solid $color-orange-900;
    border-radius: 25px;
    background-color: $color-orange-900;
    position: relative;
    top: -3rem;
    z-index: 1;
  }

  &__blok-title {
    font-size: $font-size-h5;
    font-weight: 500;
    margin: 0;
    margin-bottom: $spacing-m;
    padding: .25rem .5rem;
    color: $color-white;

    &:before {
      @extend .icon-megaphone;
      @extend .icon;
      font-size: $font-size-h3;
      margin-right: $spacing-xs;
      margin-bottom: .1rem;
    }

    &.-in-main {
      margin-bottom: $spacing-l;
    }
  }

  &__content-container {
    @include flexbox();
    @include justify-content(space-between);
    overflow: hidden;
    width: 100%;
  }

  &__title {
    margin-top: 0;
  }

  &__text {
    margin-bottom: 2rem;
  }

  &__btn {
    color: $color-indigo-900;
    border: 1px solid $color-orange-500;
    background-color: $color-white;

    &:visited {
      color: $color-indigo-900;
    }

    &:hover {
      border: 1px solid $color-orange-500;
      background-color: $color-orange-100;
    }
  }

  &__image-box {
    position: relative;
    overflow: hidden;

    &.-in-sidebar {
      top: -3.95rem;
      bottom: -$spacing-l;
      height: calc(100% + 5.95rem);
      margin: 0;
    }
	  
    &.-left {
      margin-left: -2rem;
      border-radius: 0.5rem 0 0 0.5rem;
      }

    &.-right {
      margin-right: -2rem;
      border-radius: 0 0.5rem 0.5rem 0;
      }
    
    &.-foto, &.-video {
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
	    background-position: center;
      }

    &.-video span.icon-youtube {
        font-size: 3rem;
        color: #000000;
        display: block;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
	  
    &.-video a {
      display: block;
      width: 100%;
      height: 100%;
    }

    &.-illu {
      .c-in-de-kijker-block {
        &__image {
          position: absolute;
          max-width: 100%;
          left: $spacing-ll;
          bottom: 0;
        }
      }
    }
  }

  &__image {
    position: relative;
    max-width: 100%;

  }

  &.-only-image {
    position: relative;
    width: 100%;
    padding: 0;
    border: unset;
    border: 0;
    background-color: unset;
    max-height: fit-content;
  }

  &__action-group {
    @include flexbox();
    @include flex-wrap(wrap);
    position: absolute;
    bottom: $spacing-l;
    left: $spacing-l;
    gap: $spacing-m;
  }

  @include screenMedium() {
    .c-in-de-kijker-block__image-box {
      &.-illu {
        .c-in-de-kijker-block {
          &__image {
            left: 0;
            width: 90%;
          }
        }
      }
    }
  }
}
.in-de-kijker-blok-v2 .o-2-col .c-in-de-kijker-block {
  &__image-box {
    &.-illu {
      .c-in-de-kijker-block {
        &__image {
          width: 300px;
          height: auto;
        }
      }
    }
  }
}
.in-de-kijker-blok-v2 .o-3-col .c-in-de-kijker-block {
  &__image-box {
    &.-illu {
      .c-in-de-kijker-block {
        &__image {
          width: 250px;
          height: auto;
        }
      }
    }
  }
}
img.in-de-kijker-blok-v2 {
  &__external-illu { 
    display: none;
  }
}


@include screen() {
  .in-de-kijker-blok-v2 .c-in-de-kijker-block {
    padding: $spacing-s;

    &__blok-pill {
      top: -2rem;
    }

    &__text {
      margin-bottom: $spacing-s;
    }

    &__btn {
      width: calc(100% - 2rem); // -2rem cause 100% makes them overflow
    }

    &__image-box {
      &.-foto, &.-video {
      display: none;
      grid-row-start: 1;
      margin-right: -1rem;
      margin-left: -1rem;
      border-radius: .5rem .5rem 0 0;
      }

	  /*&.-right, &.-mid {
        grid-row-start: 1;
      }
		
      &.-right, &.-left, &.-mid {
        margin-right: -1rem;
        margin-left: -1rem;
        border-radius: .5rem .5rem 0 0;
      }*/

      &.-illu {
        display: none;
      }
    }

    .o-2-col {
      -ms-grid-columns: 2fr 2rem 1fr;
      grid-template-columns: 2fr 1fr;
      grid-template-areas: 'side .';
    } 
  }

}

@include tablet() {
  .in-de-kijker-blok-v2 .c-in-de-kijker-block {
   
    &__image-box {
      &.-foto, &.-video {
        display: initial;
        height: 200px;
        margin-top: 1rem;
        margin-bottom: -4rem;
      }
		
      &.-left, &.-right {
        margin-right: -1rem;
        margin-left: -1rem;
      }	
    }

    &.-only-image {
      img {
        height: 200px;
        object-fit: cover;
      }
    }  
    
  .o-2-col {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: none;
    }
  }

  img.in-de-kijker-blok-v2 {
    &__external-illu { 
      display: block;
      position: relative;
      max-width: 40%;
      bottom: -1px;
      right: -11rem;
     // transform: scaleX(-1);
      z-index: -1;
    }
  }
} 
