.c-banner {

  &.-warning {
    background-color: $color-orange-200;
  }

    
  &.-info {
    background-color: $color-blue-200;
  }

  &.-success {
    background-color: $color-lightgreen-200;
  }

  &__container {
    @include display-grid();
    padding: $spacing-s 0;

    grid-template-columns: 2.5rem auto;
    grid-gap: $spacing-s;

  }

  &__icon {

    &.-wrench:before {
      @extend .icon;
      @extend .icon-fa-wrench-light;
      font-size: 40px;

    }
    &.-info:before {
      @extend .icon;
      @extend .icon-fa-info-circle-light;
      font-size: 40px;

    }

    &.-warning:before {
      @extend .icon;
      @extend .icon-warning-light;
      font-size: 40px;
    }
  }

  &__text {
    line-height: 22px;
    max-width: 800px;
  }

  &__ctas {
    @include flexbox();
    @include align-items(center);

    gap: $spacing-s;
  }

  &__form {
    @include flexbox();
    @include align-items(center);
    width: auto;

    gap: $spacing-s;
  }

  &__info-icon {
    display: none;
  }
}

@include screen() {
  .c-banner {
    &__content {
      @include flex-direction(column);
    }
  }
}
@include tablet() {
  .c-banner {
    &__container {
      grid-template-columns: auto;
    }

    &__icon {
      display: none;
    }

    &__info-icon {
      display: revert;
    }

    &__form {
      flex-wrap: wrap;
    }
  }
}
@include phone() {
  .c-banner {
    &__content, &__form {
      gap: $spacing-xs;
    }
  }
}