table, .c-table {
  width: 100%;
  max-width: 100%;

  td, th {
    @include tablet() {
      &.non-vital {
        display: none;
      }
    }
    min-width: auto;
    padding: .5rem;

    &.no-wrap {
      white-space: nowrap;
    }

    &.align-right {
      text-align: right;
    }

    &.align-center {
      text-align: center;
    }

    .action-lone { // fix weird font issue of data-title with icon-only cell content
      display: block;
      margin: 0 auto;
    }
  
  }

  th, .c-table-header {
    font-size: .875rem;
    color: $color-grey-900;
    border-bottom: 1px solid $color-grey-400;

    &.col-small {
      width: 1rem;
      white-space: nowrap;
    }
    
  }

  td {
    border-bottom: 1px solid $color-grey-200;

    &.fixed-width {
      width: 100%;
      white-space: initial;
    }

  }

  tr {
    &.row-passive {
      opacity: .5;
    }
  }

  tbody.row-clickable tr {
    &:hover {
      cursor: pointer;
      background-color: $color-grey-100;
    }
  }

}


.c-table {
  &__th, th {
    border: 1px solid $transparent-black-10;

    &.-blank {
      border: 0;
      background-color: $color-white;

    }
  }

  &__td, td {
    border: 1px solid $transparent-black-10;

    &.-blank {
      border: 0;
      background-color: $color-white;
    }
  }
  &__caption-heading {
    font-weight: 500;
    padding: .5rem;
    text-align: center;
    color: $color-white;
    background: $color-blue-900;
  }


  &__tr, tr {
    background-color: $color-white;

    &.-dark {
      background-color: $color-blue-900;

      .c-table__th, th {
        color: $color-white;
      }

      .c-table__td, td {
        color: $color-white;
      }
    }
  }
  
  &.-zebra {
    tbody .c-table__tr:nth-of-type(odd), tbody tr:nth-of-type(odd) {
      background-color: $color-grey-100;
    }

    .c-table__td, td {
      padding: $spacing-xs;
    }

    .c-table__th, td {
      padding: $spacing-xs;
    }
  }
}
@include phone() {
  // Class that makes tables responsive
  .table-responsive {
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 1px solid $transparent-black-10;
    }

    td.non-vital {
      display: none;
    }

    td {
      padding-left: 10px;
      text-align: left;
      white-space: normal;
      /* Behave  like a 'row' */
      border: 0;
    }

    td:before {
      font-weight: normal;
      display: block;
      content: attr(data-title);
      text-align: left;
      /* Now like a table header */
      white-space: nowrap;
      color: $transparent-black-50;
    }

    tbody.-zebra\@mobile {
      tr:nth-of-type(2n) {
        background-color: $color-grey-100;
      }
    }
  }


  table.table-responsive td .action-lone { // fix weird font issue of data-title issue with icon-only cell content
    margin: 0;
  }
}
