.c-article {
  @include flexbox();
  @include flex-direction(column);
  position: relative;
  padding-top: 0;
  padding-bottom: 2rem;
  border-bottom: 1px solid $color-grey-500;

  &.-with-image {
    padding: 0 $spacing-s $spacing-s $spacing-s;
    padding-bottom: 2rem;
    border: 1px solid $color-grey-500;
    border-radius: .5rem;

    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  &.-no-border {
    padding: 0;
    padding-bottom: 1.5rem;
    border: 0;
  }

  &__image-box {
    height: 8rem;
    margin: -$spacing-s;
    margin-top: 0;
    margin-bottom: $spacing-m;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: .5rem .5rem 0 0;

    object-fit: cover;
  }

  &__category {
    margin-bottom: 0;
  }

  &__title {
    margin-top: 0;
  }

  &__description {
    margin-bottom: 1rem;

    flex-grow: 1;
  }

  &__tag {
    margin-right: $spacing-s;
  }


  &.-featured { 

    .c-article__feature {
      @include flexbox();
      @include flex-direction(column);
      @include align-items(flex-end);
    }
    
    .c-article__feature-image-box {
      overflow: hidden;
      width: 100%;
      max-height: 20rem;
      margin: 0;
    }

    .c-article__feature-image {
      width: 100%;
      height: auto;

      object-fit: cover;
    }
  }
}

