.form-container {
  @include screen {
    box-sizing: content-box;
    border-top: 1px solid $transparent-black-10;
    border-right: 0;
    border-bottom: 1px solid $transparent-black-10;
    border-left: 0;
  }
  box-sizing: border-box;
  width: unset;
  border: 1px solid $transparent-black-10;
  background-color: $color-blue-100;

  &.-bg-spacing {
    @include screen {
      margin-right: -$spacing-m;
      margin-left: -$spacing-m;
    }
    padding: $spacing-l $spacing-m;
  }

  &.-bg-transparent {
    border: unset;
    background-color: transparent;
  }

  /* List items hebben een margin-top zodat ze mooi uitkomen in een text.
  In de form willen we echter at de list mooi gelijk start met het label.
  bij  een inset list willen we wél de padding behouden */
  ul li:first-child:not(li ul li:first-child) {
    margin-top: 0;
  }

  /* styling of placeholder items */
  ::-webkit-input-placeholder, ::-ms-input-placeholder, ::placeholder {
    color: $color-grey-700;
  }

  .inset-fieldset {
    @include screen {
      display: none;
      margin-left: $spacing-s;
    }
    margin-left: $spacing-m;

  }

  .fieldset {
    @include screen {
      margin: $spacing-l -.75rem 0;
      padding: $spacing-l .75rem;
    }
    margin-top: $spacing-l;

    &:first-child {
      margin-top: 0;
    }
  }

  legend {
    margin-left: 0;
  }
}

.basic-form {

  /* styling of a row */
  .form-row {
    width: 100%;
    margin-top: $spacing-l;

    &.o-btn-group {
      margin-top: $spacing-s;
    }

    /* left side of the row */
    .form-label {
      margin-bottom: $spacing-xxs;

      label, legend {
        font-weight: $headings-font-weight;
      }
    }

    /* fix error and succes states for form field icon */
    .form-field-icon.field-error {
      input[type='text'], input[type='email'], input[type='password'], input[type='search'], select, textarea, input[type='checkbox'], input[type='radio'], input[type='date'], input[type='time'] {
        border-color: $color-red-700;
      }

      input[type='checkbox']:checked, input[type='radio']:checked {
        border-color: $color-blue-500;
      }

      .textarea-meta {
        color: $color-red-700;
      }
    }
    .form-field-icon.field-success {

      input[type='text'], input[type='email'], input[type='password'], input[type='search'], select, textarea {
        border-color: $color-green-700;
      }
    }

    /* right side of the row */
    .form-field {
      @include flexbox();
      flex-direction: column;
      flex-wrap: wrap;

      grid-area: formField;
      -ms-grid-row: 1;
      -ms-grid-column: 3;
      -ms-grid-column-span: 3;

      //multiple columns with checkboxes or radiobuttons
      .multi-cols {
        label {
          display: inline-flex;
          width: 100%;
          margin: 0;
        }

        &.-c2 {
          column-count: 2;
        }

        &.-c3 {
          column-count: 3;
        }

        &.-c4 {
          column-count: 4;
        }

        @media (max-width: $screen-small) {
          &.-c4 {
            column-count: 3;
          }
        }

        @media (max-width: $tablet) {

          &.-c3, &.-c4 {
            column-count: 2;
          }
        }

        @media (max-width: $phone) {

          &.-c2, &.-c3, &.-c4 {
            column-count: 1;
          }
        }
      }

      .multi-fields { 
        @include screenMedium {
          &.multi-fields-4c, &.multi-fields-5c {
            @include display-grid;

            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-area: formField;
            -ms-grid-row: 1;
            -ms-grid-column: 3;
            -ms-grid-column-span: 3;
          }
        }
        @include tablet {
          &.multi-fields-3c, &.multi-fields-4c, &.multi-fields-5c {
            @include display-grid;

            grid-template-columns: 1fr 1fr 1fr;
            grid-area: formField;
            -ms-grid-row: 1;
            -ms-grid-column: 3;
            -ms-grid-column-span: 3;
          }
        }
        @include phone {
          @include flex-direction(column);
          flex-wrap: wrap;
          width: 100%;

          &.multi-fields-2c, &.multi-fields-3c, &.multi-fields-4c, &.multi-fields-5c {
            @include display-grid;

            grid-template-columns: 1fr 1fr;
            grid-area: formField;
            -ms-grid-row: 1;
            -ms-grid-column: 3;
            -ms-grid-column-span: 3;
          }
        }
        @include flexbox();
        @include flex-direction(row);
        .c-btn.field-icon, /* fall back for old class ".btn" TO BE REMOVED => */.btn.field-icon {
          @include flexbox;
          @include justify-content(center);
          @include align-items(center);
          @include phone {
            margin-top: $spacing-s;
          }
          box-sizing: border-box;
          width: 40px;
          height: 48px;
          margin-top: 0;
          padding: $spacing-xxs;
        }

        &.multi-fields-5c {
          @include display-grid;
          @include display-grid;

          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-area: formField;
          -ms-grid-row: 1;
          -ms-grid-column: 3;
          -ms-grid-column-span: 3;
        }

        &.multi-fields-list {
          @include flex-direction(column);

          ul {
            margin-left: $spacing-m;
          }
        }

        //multiple fields in a row
        .multi-field {
          @include flexbox;
          @include flex-direction(column);
          @include justify-content(flex-end);
          @include phone {
            margin-right: 0;
          }
          @include tablet {
            width: 100%;
          }
          //width: 384px;
          margin-right: $spacing-s;

          &:last-child {
            margin-right: 0;
          }

          input, select, textarea {
            width: 100%;
          }
        }

        .multi-field-small {
          width: 120px;
        }

        &.auto-width {

          .multi-field, .form-field-icon {
            width: auto;

            flex-grow: 1;
          }
        }

      }

      .inline-notification {
        margin-top: $spacing-xxs;
      }

      .field-infoblock {
        @include flexbox;
        @include justify-content(space-between);
        margin-top: $spacing-xxs;

        .inline-notification {
          margin-top: 0;
        }
      }

      &.field-error {

        input[type='text'], input[type='email'], input[type='password'], input[type='search'], select, textarea, input[type='checkbox'], input[type='radio'], input[type='date'], input[type='time'] {
          border-color: $color-red-700;
        }

        input[type='checkbox']:checked, input[type='radio']:checked {
          border-color: $color-blue-500;
        }

        .textarea-meta {
          color: $color-red-700;
        }
      }

      &.field-success {

        input[type='text'], input[type='email'], input[type='password'], input[type='search'], select, textarea {
          border-color: $color-green-700;
        }
      }

    }

    &.row-header {
      margin: 0;

      h2 {
        margin-top: 0;
      }
    }

    
    
    &.row-footer {
      @include screen {
        margin-top: $spacing-l;
      }
      margin-top: $spacing-l;

      &.o-btn-group {
        margin-top: $spacing-s;
      }

      // !! ".form buttons" is depracated and replaced by ".o-btn-group" defined in /huisstijl/src/scss/components-v3/page-elements/_buttons.scss !!
      .form-buttons {
        @include tablet {
          @include flexbox;
          flex-wrap: wrap;
        }

        .btn {
          @include tablet {
            margin-bottom: $spacing-s;
  
            &:first-child {
              margin-right: $spacing-m;
            }
          }
          margin-right: $spacing-m;
          margin-bottom: 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.nonstacked-form {
  @extend .basic-form;
  // !!! MOBILE FIRST query because ALL styling should be the same as in basic form, unless screen width is bigger than "minTablet" = 680px !!! //
  @include minTablet {
    .form-row {
      @include display-grid;

      grid-template-columns: 1fr 4fr;
      -ms-grid-columns: 1fr $gap 4fr;
      grid-gap: $spacing-s;
      grid-template-areas: 'formLabel formField formField';

      .form-label {
        @include flexbox;
        @include flex-direction(column);
        margin-bottom: 0;
        text-align: right;

        grid-area: formLabel;
        -ms-grid-row: 1;
        -ms-grid-column: 1;

        /* ensures a nice aligning of the inputfield when combined with a text input */
        &.align-to-textfield {
          margin-top: 11px;
        }

        .tag {
          margin-top: -11px;
          margin-left: 0;
        }

        .label-radio, .label-checkbox {
          margin-top: 9px;
        }
      }

      &.row-header {
        grid-template-areas: '. headerContent headerContent';

        .header-content {
          grid-area: headerContent;
        }
      }

      &.row-footer {
        grid-template-areas: '. footerContent footerContent';

        .footer-content {
          grid-area: footerContent;
        }
      }
    }
  }

  .tag {
    margin-left: $spacing-xs;
  }
}

.inline-edit-form {
  @extend .basic-form;
  @include phone {
    .form-row {

      .form-value {
        @include flexbox;
        @include flex-direction(row);
        margin-top: $spacing-xxs;
  
        a {
          white-space: nowrap;
        }
      }
  
      .form-field {
        .btn:before {
          padding: 0;
        }
  
        .inline-controls {
          @include flexbox;
          margin-top: $spacing-xxs !important;
          margin-left: 0 !important;
  
          .action-submit {
            margin-right: $spacing-xxs;
          }
        }
  
        &.field-radio, &.field-checkbox {
  
          .multi-fields {
            @include flex-direction(column);
          }
  
          .inline-controls {
            @include flexbox;
            margin-top: $spacing-xxs;
            margin-left: 0;
          }
        }
      }
    }
  }
  @include tablet {
    .form-row {
      .form-field {
  
        &.field-radio, &.field-checkbox {
          .multi-fields {
            @include flex-direction(row);
            @include align-items(center);
          }
        }
      }
    }
  }

  .form-row {
    .form-value {
      @include flexbox;
      @include flex-direction(row);
      margin-top: $spacing-xxs;

      a {
        white-space: nowrap;
      }
    }
    .form-field {
      display: none;
      .inline-controls {
        margin-top: 0;
        margin-left: $spacing-s;
      }

      &.field-radio, &.field-checkbox {
        .multi-fields {
          flex-wrap: wrap;
        }
        .inline-controls {
          margin-left: 0;
        }
      }

      &.field-textarea {
        .multi-fields {
          @include flex-direction(row);
          @include justify-content(space-between);
          flex-wrap: wrap;
        }
        .inline-controls {
          margin-top: $spacing-xxs;
          margin-left: 0;
        }
      }
    }
  }

  .form-field-icon {
    @include tablet {
      max-width: 100%;
    }
  }
}

.nonstacked-form.inline-edit-form {
  @include tablet {
    .form-row:not(.row-header, .row-footer) {
      .form-field {
        margin-top: 0 !important;

        .multi-fields {
          flex-wrap: nowrap;

          .inline-controls {
            margin-top: 0;
            margin-left: $spacing-s;
          }
        }

        &.field-radio, &.field-checkbox, &.field-textarea {
          .multi-fields {
            flex-wrap: wrap;
          }
        }
      }
    }

    .form-field-icon {
      input, select, textarea {
        width: 100%;
      }
    }
  }
  @include screenMedium {
    .form-row:not(.row-header, .row-footer) {
      .form-field:not(.field-radio, .field-checkbox, .field-textarea) {
        .inline-controls {
          margin-top: 0;
          margin-left: $spacing-s;
        }
      }
    }

    .form-field-icon {
      input, select, textarea {
        width: 100%;
      }
    }
  }

  .form-row {
    .form-value {
      margin-top: 0 !important;
    }
  }
  .form-row:not(.row-header, .row-footer) {
    .form-field {
      margin-top: -11px;
      .inline-controls {
        margin-top: 0;
        margin-left: $spacing-s;
      }

      &.field-radio, &.field-checkbox, &.field-textarea {
        .multi-fields {

          .inline-controls {
            margin-top: 0;
            margin-left: 0;
          }
        }
      }
      &.field-textarea {
        .multi-fields {
          .inline-controls {
            margin-top: $spacing-xxs;
            margin-left: 0;
          }
        }
      }
    }
  }

}