.c-ibo-widget,
.c-ibo { // legacy
  &__slat {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(flex-start);
    flex-wrap: wrap;
  }

  &__slat-title {
    position: relative;
    padding-right: $spacing-l;
    flex-basis: 20%;
  }

  &__slat-status {
    font-size: $text-small;
    text-transform: uppercase;
  }

  &__cta {
    flex-basis: 30%;
  }

  &__alert {
    display: inline-block;
    padding: $spacing-xxs $spacing-xs;
    text-decoration: none;
    border-radius: 7px;

    &.-info {
      color: $color-blue-700;
      border: 1px solid transparentize($color: $color-blue-700, $amount: .85);
      background-color: $color-blue-100;
    }

    &.-warning {
      color: $color-orange-700;
      border: 1px solid transparentize($color: $color-orange-700, $amount: .85);
      background-color: $color-orange-100;
    }

    &.-danger {
      color: $color-danger;
      border: 1px solid transparentize($color: $color-danger, $amount: .85);
      background-color: $color-red-100;
    }
  }

  &__alert-text {
    margin-right: .5rem;
  }

  &__alert-link {
    font-weight: 500;
    color: inherit;
    text-decoration-color: inherit;

    &:visited {
      color: inherit;
      text-decoration-color: inherit;
    }

    &:hover {
      text-decoration-color: inherit;

      .-info & {
        color: darken($color-blue-700, 15%);
      }
  
      .-warning & {
        color: darken($color-orange-700, 15%);
      }
  
      .-danger & {
        color: darken($color-danger, 15%);
      }
    }
  }
  
  &__dates {
    margin-right: auto;
    margin-left: auto;
    text-align: right;
    flex-basis: 15%;
  }

  &__contact {
    flex-basis: 20%;
  }

  .c-empty-state-ibo {
    background-image: url('https://cdn.vdab.be/img/components/cta-block/Jobs-horizontaal.svg');
  }

  & .has-loading__box {
    margin-top: $spacing-xs;
  }

  .action-more-after {
    margin-right: $spacing-s;
  }
  
  .action-more-after:nth-of-type(3) {
    float: right;
  }
}

@include screen() {
  .c-ibo-widget,
  .c-ibo { // legacy
    &__slat {
      @include flex-direction(column);
    }

    &__slat-title {
      width: 100%;
    }

    &__dates {
      @include flexbox();
      width: 100%;
      margin: unset;

      & *:first-child {
        margin-right: $spacing-s;
      }

      & .has-loading__box {
        width: 30%;
      }
    }

    &__cta {
      margin-top: $spacing-s;
      order: 1;
    }

    &__contact {
      @include flexbox();
      @include justify-content(space-between);
      width: 100%;
      margin-top: $spacing-xs;

      & *:not(last-child) {
        margin-right: $spacing-l;
      }

      & .has-loading__box {
        width: 40%;
      }
    }

    .c-empty-state-ibo {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/Jobs.svg');
    }
  }
}

@include tablet() {
  .c-ibo-widget,
  .c-ibo { // legacy
    .action-more-after:nth-of-type(3) {
      float: unset;
    }
  }
}

@include phone() {
  .c-ibo-widget,
  .c-ibo { // legacy
    &__contact {
      @include flex-direction(column);
    }
  }
}