.c-contact-block {

  &__block-title {
    color: $color-turquoise-700;

  }

  &__description {
    font-weight: 500;
    margin-bottom: 0;
    color: $color-grey-700;
  }

  &__name {
    font-weight: 500;
    margin-bottom: 0;
  }


  &__link-container {
    position: relative;

    &:not(:last-child) {
      margin-bottom: $spacing-xs;
    }
    &:before {
      position: absolute;
      top: .4rem;
      display: block;
      text-decoration: none;
      color: $color-grey-700;
    }
  }

  &__link {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: $spacing-s;
  }

}