//grid overrule from burger version

#indi-partner-header {

  .c-main-navbar__megamenu-link {
    .c-partner-navbar__partners & {
      padding-left: 0; //overrule from burger
    }
  }
  
  .c-partner-navbar {
    &__stretch-wrapper {
      background-color: $color-purple-500;
    }

    &__vdab-menu {
      @extend .icon;
      @extend .icon-mobile-menu;
      @include align-self(stretch);
      @include flexbox();
      @include align-items(center);
      font-size: 1.25rem;
      padding: $spacing-m $spacing-s;
      text-decoration: none;
      color: $color-white;
      background-color: $color-purple-700;
  
      &:before {
        padding-right: $spacing-xs;
      }
  
      &.is-active {
        @extend .icon-close;
        background-color: $color-purple-900;
      }
  
      &:hover {
        text-decoration: none;
        color: $color-white;
        background-color: $color-purple-900;
      }
  
      &:visited {
        color: $color-white;
      }
    }
  
    .c-main-navbar__routing-link.-auth-menu::after, .c-main-navbar__account-title::after {
      display: none;
    }
  
    .c-dropdown__menu.c-partner-navbar__other-accounts-menu { // only on desktop
      overflow-y: scroll;
      min-width: 320px;
      max-height: 80vh; 
  
      .-show-all {
        @extend .pt1, .pb1;
        border-top: 1px solid $color-grey-500;
      }
    }


    //css om main navbar te overschrijven voor anonieme versie 
    
    .c-main-navbar__megamenu-wrapper {
      border-color: $color-purple-700;
      background: $color-white;
    }
  
    &.-auth .c-main-navbar__megamenu-wrapper {
      background: linear-gradient(90deg, $color-grey-100 50%, $color-white 50%);
    }
    .c-main-navbar__megamenu-container {
      padding: 2rem 0;

      grid-template-columns: 1fr;
      .c-main-navbar__megamenu.u-reset-list {
        display: none;
      }
    }

    &.-auth .c-main-navbar__megamenu-container {
      padding: 0;

      grid-template-columns: 1fr 2fr;
      grid-template-areas: 'account menulinks';
      .c-main-navbar__megamenu.u-reset-list {
        display: grid;
      }
    }

    .c-main-navbar__account-anon-menu {
      grid-area: unset;
    }


    &__anon-menu-container {
      @include flexbox();
      background-color: $color-grey-100;

      gap: 3rem;
    }

    &__image {
      max-width: 20%;
    }

    
    &__other-vestiging { // legacy
      @extend .u-show\@screen;
    }
    
    &__other-accounts-menu { 
      line-height: 120%;
    }
   
    &__search-btn {
      @include align-self(stretch);
      padding: $spacing-xxs !important;
      color: $color-white;
      border-color: $color-purple-900;
      border-radius: 0 5px 5px 0 ;
      background-color: $color-purple-700;
  
      &:focus {
        box-sizing: border-box;
      }
  
      &:hover {
        background-color: $color-purple-900 !important;
      }
  
      &:before {
        padding-right: 0;
      }
    }
  
    .c-main-navbar__search-input {
      border-radius: 5px 0 0 5px;

    }
    &__account-avatar.c-account-bullet {
      background-color: $color-blue-900;
    }

  }

  
  .c-partner-navbar:not(.-werkgever) {
    .c-partner-navbar__werkgevers {
      display: none;
    }
  }
  
  .c-partner-navbar.-werkgever.-auth {

    .c-partner-navbar__werkgevers {
      border-top: 1px solid $color-purple-900;



      .o-wrapper {
        position: relative;         
      }
    }
    

    .c-partner-navbar__werkgevers-site-nav {
      position: absolute;
      top: $spacing-l;
      right: 0;
    }
  


    .c-partner-navbar__werkgevers-menu {
      column-count: 5;
    }

    


    .c-partner-navbar__werkgevers-link {
      display: inline-block;
    }
  }

  .c-partner-navbar.-werkgever:not(.-auth) {
    .c-partner-navbar__werkgevers {
      display: none;
    }
  }
  

  
}

#indi-partner-footer.c-partner-footer {
  color: $color-white;
  background-color: $color-purple-500;

  .c-footer__button-group__button { 
    border-color: $color-white;
    background-color: transparent; 
    
    &:hover, &:focus {
      background-color: $transparent-white-25;
  }
  }
}

@include screenMedium() {

  #indi-partner-header .c-partner-navbar .c-main-navbar__account-anon-menu {
    padding-top: 0;
  }

  #indi-partner-header .c-partner-navbar .c-partner-navbar__anon-menu-container {
    padding: 1rem;
  }
  #indi-partner-header .c-partner-navbar .c-partner-navbar__image {
    display: none;
  }
  #indi-partner-header .c-partner-navbar.-auth .c-main-navbar__megamenu-wrapper {
    background: $color-white;
  }

  #indi-partner-header .c-partner-navbar.-auth .c-main-navbar__megamenu-container {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: 'account'
    'menulinks'
    'partners'
    'dropdown-footer';
  }

  #indi-partner-header {
    .c-main-navbar {
      &__footer-subnavigation {
        .c-navbar-partner & {
          display: block;
        }
      }
    }
  
    .o-wrapper {
      .c-partner-navbar__werkgevers {
        margin-right: 0; //overrule from burger
        margin-left: 0; //overrule from burger
      }
    }
  
    .c-main-navbar__megamenu-container {
      .c-partner-navbar & {
        grid-template-areas: 'account'
        'menulinks'
        'partners'
        'dropdown-footer';
      }
    }
  
    .c-main-navbar__megamenu-link {
      padding-left: $spacing-m; //reset from desktop overrule 
    }
  
    .c-partner-navbar.-werkgever {
  
      .c-partner-navbar__werkgevers-site-nav {
        display: none;
      }
  
      .c-partner-navbar__werkgevers {
        border-top: 0;

        grid-area: partners;
      }
  
      .c-partner-navbar__werkgevers-menu {
        column-count: 2;
      }
  
      .c-partner-navbar__werkgevers-link {
        display: block;
        padding-left: $spacing-m;
      }
      
      .c-main-navbar__account-title::after {
        display: block;

        order: 2;
      }

      .c-main-navbar__megamenu-link {
        padding-left: $spacing-m; //reset from desktop overrule 
    }
    }
  }

}
@include screen() {

  #indi-partner-header {
    .c-partner-navbar__other-accounts-menu { // legacy
      &-selector.c-btn.-ninja, &-selector.c-btn.-ninja:focus {
        padding-top: $spacing-xs;
      }
      
      &-options {
        margin-top: $spacing-xs;
        padding-left: 0;
        list-style: none;
        text-align: left;
    
        li {
          margin: 0;
        }
  
        li a {
          display: block;
          padding: $spacing-xs $spacing-s;
          cursor: default; //the negate the effects of clickable topelements like a button
          white-space: nowrap;
          text-decoration: none;
          color: $color-grey-900;
  
          &:hover, &:focus {
            text-decoration: none;
            color: $color-white;
            background-color: $color-blue;
  
            .u-text-meta {
              color: $color-white;
            }
          }
  
          &[disabled] {
            color: $color-grey-600;
            background-color: $color-grey-100;
  
            &:hover {
              cursor: not-allowed;
              color: $color-grey-600;
            }
          }
        }
      }
    }
  }

}
@include tablet {


  #indi-partner-header .c-partner-navbar.-werkgever.-auth .c-partner-navbar__werkgevers-menu {
    column-count: 1;
  }


}