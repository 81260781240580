.c-bidk-widget {
  @include flexbox();
  @include flex-direction(row);
  @include flex-wrap(wrap);

  &__logo {
    @include flex-basis(14.28%);
    position: relative;
    box-sizing: border-box;
    padding: 2rem 1rem;
    text-align: center;
    border: 1px solid $color-white;

    &:hover {
      .c-bidk-widget__img {
        filter: none;
      }
    }
  }

  &__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .2;
    background-color: $color-blue-300;
  }

  &__img {
    max-width: 100%;
    max-height: 3rem;

    filter: grayscale(1);
  }
}

@media (max-width: $screen-small) {
  .c-bidk-widget {
    &__logo {
      @include flex-basis(25%);
    }
  }
}

@media (max-width: $phone) {
  .c-bidk-widget {
    &__logo {
      @include flex-basis(50%);
    }
  }
}


.c-bidk-container {

  .c-card {
    &__clickable-header {
      .o-routing-link {
        font-size: 1.3rem;
        line-height: 1.7rem;
      }
    }

    &__content {
      margin-bottom: 0;
    }
    &__logo-box {
      margin-bottom: .5rem;
    }
  }

  &.-no-suggestions {
    display: unset;
  }
}


.c-bidk2 {
  @include flexbox();
  @include flex-direction(column);

  &__widget {
    @include flexbox();
    @include flex-direction(column);
    overflow: hidden;
    padding: $spacing-m;
    background-color: $color-grey-100;

    flex-grow: 1;
  }


  &__highlight {
    @include flexbox();
    @include flex-direction(column);

    gap: $spacing-s;
    flex-grow: 1;

    .-no-suggestions & {
      @include flex-direction(row);
      @include align-items(flex-start);
      @include tablet() {
        @include flex-direction(column);
      }
    }

  }


  &__highlight-logo {
    max-width: 7rem;

    .-no-suggestions & {
      width: 12rem;
      max-width: unset;
    }
  }

  &__highlight-content {
    .-no-suggestions & {
      @include tablet() {
        max-width: unset;
      }
      max-width: 66%;
    }
  }
  &__highlight-title {
    width: 100%;
  }

  &__highlight-text-container {
    flex-grow: 1;

  }
  
  &__highlight-text {
    display: -webkit-box;
    overflow: hidden;
    width: 100%;

    -webkit-line-clamp: 13;
    -webkit-box-orient: vertical;

    .-no-suggestions & {
      -webkit-line-clamp: 4;
    }
  }

  &__highlight-btn {
    box-sizing: border-box;
    width: 100%;

    .-no-suggestions & {
      width: unset;
    }
  }
  &__logo-container {
    @include display-grid();
    @include repeat-template-columns(2, 1fr);
    @include screen() {
      @include repeat-template-columns(4, 1fr);

      gap: $spacing-m;
    }
    @include tablet() {
      @include repeat-template-columns(2, 1fr);

      gap: $spacing-m;
    }

    gap: $spacing-m;

    .-no-suggestions & {
      @include repeat-template-columns(6, 1fr);
      @include screen() {
        @include repeat-template-columns(4, 1fr);

        gap: $spacing-m;
      }
      @include tablet() {
        @include repeat-template-columns(2, 1fr);

        gap: $spacing-m;
  
      }

      gap: $spacing-m;

    }
  }

  &__logo {
    box-sizing: border-box;
    width: 100%;
    padding: $spacing-xs;
    background-color: $color-white;

    &:nth-child(n + 5) {
      display: none;
    }

    .-no-suggestions & {
      @include tablet() {
        &:nth-child(n + 5) {
          display: none !important;
        }
      }
      @include screen() {
        &:nth-child(n + 9) {
          display: none !important;
        }
      }
      &:nth-child(n + 5) {
        display: block;
      }
    }
  }


}
