/* check if the appearance none attr is supported. 
If not, default browser styling will be used. */
@supports(-webkit-appearance: none) or (-moz-appearance: none) {

  .checkbox {
    /* used to make sure tht the second line of a label 
    starts at the same point as the first line (so not under the checkbox) */
    @extend .radiobutton;

    &__sub-label {
      position: relative;
      margin-left: 24px;
      padding-right: 24px;
      color: $color-grey-700;

      &.-hidden\@phone {
        @include phone() {
          display: none;
        }
      }

      &.-expanded\@phone:after {
        @include phone() {
          line-height: 1.25rem;
          position: absolute;
          z-index: -1;
          top: 0;
          right: 9px;
          display: block;
          width: .06rem;
          height: 100%;
          margin-right: 2px;
          content: '';
          background-color: $color-indigo-900;
        }
      }
    }

    &__show-sub-label {
      position: absolute;
      right: 0;
    }
  }

  /* general styling checkbox - absolute positioned so that a multi-line label
  always starts at the beginning of the label and not under the checkbox */
  input[type='checkbox'] {
    @extend .input-type-radio;
    transition: background .3s, border-color .3s, box-shadow .2s;
    border-radius: 2px;

    /* styling for the checkmark in the checkbox*/
    &:after {
      display: block;
      width: 3px;
      height: 7px;
      margin-top: 1px;
      margin-left: 4px;
      content: ' ';
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      opacity: 0;
      border: solid $color-white;
      border-top: 1px;
      border-left: 1px;
      border-radius: 1px;
    }

    /* different states checkbox */
    &:checked {
      border-color: $color-blue-500;
      background-color: $color-blue-500;

      &:after {
        opacity: 1;
      }
    }

    &:focus {
      outline: 2px solid $color-blue-400;
    }

    &:disabled {
      border-color: $color-grey-300;

      &:checked {
        border-color: $color-grey-500;
        background-color: $color-grey-500;

        &:after {
          opacity: 1;

        }
      }

    }
  }


}



@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .c-checkbox-v4 {
    
    &__legend {
      font-weight: $headings-font-weight;
      margin-bottom: $spacing-xxs;
      margin-left: 0;
    }

    &__optional {
      font-size: 1rem;
      font-weight: $headings-font-weight;
      text-transform: uppercase;
      color: $color-grey-800;
    }

    &__option {
      @include flexbox();
      @include align-items(flex-start);
      margin-top: 0;
      margin-bottom: 0;

      gap: $spacing-xs;

      &:not(:first-child) {
        margin-top: $spacing-xs;
      }

      .checkbox {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__label {
      cursor: pointer;
    }

    
    &__sub-label {
      margin-bottom: 0;
      color: $color-grey-800;
    }
    
    &__input {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      display: block;
      width: 1.125rem;
      min-width: 1.125rem;
      height: 1.125rem;
      margin-top: .3rem;
      cursor: pointer;
      border-radius: .05rem;
      outline: 1.5px solid $color-grey-900;
      background-color: $color-white;

      -webkit-appearance: none;
      -moz-appearance: none;

      &:after {
        display: block;
        width: 4px;
        height: 10px;
        margin-top: -.2rem;
        content: ' ';
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
        border: 3px solid $color-white;
        border-top: 0;
        border-left: 0;
        border-radius: 1px;
      }
    }

    &__input:checked {
      outline: 1.5px solid $color-link;
      background-color: $color-link;

      &:after {
        animation: drawCheck .2s ease-in-out forwards;
        opacity: 1;
      }
    }

    &__input:disabled {
      cursor: not-allowed;
      outline: 1.5px solid $color-muted;
      background-color: $color-grey-300;

      &:checked {
        &:after {
          animation: none;
          border-right: 3px solid $color-muted;
          border-bottom: 3px solid $color-muted;
        }
      }
    }

    &__input:disabled + .c-checkbox__label, &__input:disabled + .c-checkbox__content .c-checkbox__label {
      cursor: not-allowed;
      color: $color-grey-800;
    }
    
    &__input:focus-visible {
      border: 2px solid $color-blue-400;
    }

    &__multi-columns {
      @include display-grid;

      //overwrite to prevent the margin-top on first child. Otherwise it's not well aligned
      .c-checkbox__option {
        margin: 0;
      }
  
      &.-c4 {
        @include repeat-template-columns(4, 1fr, $spacing-xs);
        @include tablet() {
          @include repeat-template-columns(2, 1fr, $spacing-xs);
        }
        @include phone() {
          @include repeat-template-columns(1, 1fr, $spacing-xs);
        }
      }
  
      &.-c3 {
        @include repeat-template-columns(3, 1fr, $spacing-s);
        @include tablet() {
          @include repeat-template-columns(2, 1fr, $spacing-s);
        }
        @include phone() {
          @include repeat-template-columns(1, 1fr, $spacing-s);
        }
      }
  
      &.-c2 {
        @include repeat-template-columns(2, 1fr, $spacing-s);
        @include phone() {
          @include repeat-template-columns(1, 1fr, $spacing-s);
        }
      }
    }
  }
}