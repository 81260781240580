.c-extranet-navbar {
  &__stretch-wrapper {
    background-color: $color-indigo-900;
  }

  &__vdab-header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    color: $color-white;
  }

  &__title {
    text-decoration: none;
    color: $color-white;

    &:visited, &:focus {
      text-decoration: none;
      color: $color-white;
    }
    
    &:hover, &:active {
      text-decoration: none;
      color: darken($color-white, 15%);
    }
  }

  &__menu-button {
    padding: 1.5rem;
    text-decoration: none;
    color: $color-white;
    border: 0;

    &:before {
      margin-right: .5rem;
    }
    
    &.-auth {
      display: none;

      .-auth & {
        display: block;
      }
    }

    &.-anon {
      display: block;

      .-auth & {
        display: none;
      }
    }

    &.-search {
      margin-left: auto;
    }

    &.-login {
      background-color: $color-indigo-700;
    }

    &:visited, &:focus {
      text-decoration: none;
      color: $color-white;
    }

    &:hover {
      text-decoration: none;
      color: $color-white;
      background-color: $color-blue-900;
    }
  }

  &__dropdown-menu {
    width: unset !important;
  }


}

@include phone() {
  .c-extranet-navbar {

    &__vdab-header {
      margin-right: 0;
    }

    &__title {
      display: none;
    }

    &__menu-button {
      &:before {
        margin-right: 0;
      }
    }

    &__menu-text {
      position: absolute;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }
  }
}