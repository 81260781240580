
.c-step-block {
  position: relative;
  padding-left: 0;
  list-style: none;
  counter-reset: counter;

  &.-with-icon {
    &:before {
      display: none;
    }
  }

  &:before {
    position: absolute;
    z-index: -1;
    left: 2rem;
    height: 100%;
    content: '';
    border-left: 2px solid $color-grey-500;
  }

  &.-horizontal {
    &:before {
      display: none;
    }
  }

  &__container {
    width: 100%;
  }
  &__item {
    @include flexbox();

    gap: $spacing-s;


    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      @include justify-content(center);
      @include align-items(center);
      font-size: $font-size-h3;
      font-weight: 500;
      display: flex !important;
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
      min-height: 3rem;
      color: $headings-color;
      border: .5rem solid $color-white;
      border-radius: 100%;
      background-color: $color-blue-300;
    }

    .-horizontal & {
      @include flex-direction(column);
      margin: 0;

      &:before {
        border: 0;
      }
    }

    .-with-counter & {
      counter-increment: counter;

      &:before {
        content: counter(counter);
      }
    }
  }

  &__title {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
    width: 100%;
    min-height: 3rem;
    margin-top: .5rem;
    margin-bottom: $spacing-s;
    padding: .5rem .5rem 0 .5rem;
    border: 0;
    border-bottom: 2px solid $color-indigo-900;
    background-color: transparent;

    gap: $spacing-s;

    &.is-clickable {
      &:hover {
        cursor: pointer;
        border-radius: $spacing-xs $spacing-xs 0 0;
        background-color: $color-blue-100;
      }
  
      &:focus-visible {
        border-radius: $spacing-xs $spacing-xs 0 0;
        outline: 2px solid $color-indigo-900;
        background-color: $color-blue-100;
      }
    }

  }

  &__arrow {
    font-size: 2rem;
    color: $color-blue-500;
  }
}

/* 

ol step block is de o-wrappper met de lijn links

<li item flex>
  before

  <span>
    <span onderlijnt + flex>
      titel
      button
    </span>
    <p> met uitleg </p>
  </span>

  </li>




*/
