.c-spinner {
  display: inline-block;
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-right: 1rem;
  animation: spin .5s infinite linear;
  background-image: url('../img/loading-dark.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px;

  .o-btn-group & {
    height: unset;
    min-height: 28px;
    margin-top: 16px;
  }

  .o-btn-group > * & {
    height: calc(100% - #{$spacing-s});
  }
}

.c-modal.-spinner {
  width: auto; // Force spinner modal to take width of text inside
}

.c-modal.-spinner .alert {
  @include flexbox();
  margin-bottom: 0;
  padding: 1rem;
}

.form-row .form-field-icon .c-spinner { // propper positioning of spinner with search and select field
  margin-top: .5rem;
  margin-left: 2rem;
}

.form-field .multi-fields .c-spinner { // propper positioning of spinner with multiple fields
  margin-top: 2.5rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}