.c-kandidaten-widget,
.c-kandidaten {

  // legacy
  &__slat {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(flex-start);
  }

  &__slat-title {
    position: relative;
    width: 100%;
    padding-right: $spacing-l;
    flex-basis: 35%;
  }

  &__vac-sent {
    margin-left: .5rem;
    white-space: nowrap;
    color: $text-meta;

    &:before {
      display: inline-block;
      width: .4rem;
      height: .4rem;
      margin-right: .5rem;
      content: ' ';
      vertical-align: middle;
      border-radius: 100%;
      background-color: $text-meta;
    }
  }

  &__notification {
    min-width: 160px;
    padding-right: $spacing-s;
    white-space: nowrap;
    flex-shrink: 0;
  }

  &__remarks {
    flex-basis: 30%;
    flex-grow: 1;
  }

  &__score {
    padding: 0 $spacing-l;
    text-align: center;
    flex-basis: 15%;
    flex-shrink: 0;
  }

  &__score-star {
    text-decoration: none;
    color: $color-grey-200;

    &.-full {
      color: $color-yellow-700;
    }
  }

  &__advies {
    margin-top: $spacing-s;
    margin-left: 176px; //160px + 1rem padding
    padding: $spacing-s;
    background: $color-grey-100;

    .c-btn.-ninja {
      text-align: left;
    }

    .c-btn.-ninja:before {
      font-weight: 700;
    }
  }

  &__advies-content {
    margin-top: $spacing-s;
    margin-left: $spacing-m;
  }

  .c-empty-state-kandidaten {
    background-image: url('https://cdn.vdab.be/img/components/cta-block/Zoeken-horizontaal.svg');
  }

  .c-dropdown__menu {
    // override
    width: auto;
    max-width: 90vw; //to prevent it going off screen on small screens
  }

  .has-loading__box {
    margin-top: 0;
    margin-bottom: $spacing-xs;
  }
}

@include screenMedium {

  .c-kandidaten-widget,
  .c-kandidaten {

    // legacy
    &__slat-title {
      flex-basis: 30%;
    }

    &__remarks {
      flex-basis: 25%;
    }
  }

}

@include screen() {
  .c-kandidaten-widget,
  .c-kandidaten {
    // legacy
    display: none; //default on smaller screens > needs toggle to activate

    &__slat {
      @include flex-direction(column);
    }

    &__slat-title {
      margin-top: $spacing-xs;
      padding: 0;
    }

    &__advies {
      margin: 0 auto 0 auto;
    }

    &__score {
      width: 40%;
      margin-top: $spacing-s;
      padding: 0;
      text-align: left;
    }

    &__remarks {
      width: 100%;
      margin-top: $spacing-xs;
      order: 1;
    }

    .c-dropdown {
      position: absolute;
      align-self: flex-end;
    }

    .c-empty-state-kandidaten {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/Zoeken.svg');
    }
  }
}