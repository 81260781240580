// VARIABLES =====================================================================================
$brand-font-regular: Flanders Art Sans,
'Myriad Pro',
'Arial',
'Helvetica',
'sans-serif';
$font-size-base: 1.125rem;
$font-size-h1: 2.8125rem;
$font-size-h2: 2rem;
$font-size-h3: 1.625rem;
$font-size-h4: 1.375rem;
$font-size-h5: $font-size-base;
$font-size-h1-mobile: 2.125rem;
$font-size-h2-mobile: 1.75rem;
//$font-path: '../../fonts/';

$headings-color: $color-indigo-900;
$headings-font-weight: 500;

$baseline: 27px; // waarom 27px eigenlijk?

$text-small: .875rem;

body, textarea, input, select, button {
  @include font-smoothing();
  font-family: $brand-font-regular;
  font-size: $font-size-base; //18px
  font-weight: 400;
  line-height: $baseline; //27px = 1.5
  text-align: left;
  color: $color-grey-900;
  //cap-height = 12px =  0.667
}

// HEADINGS ================================================================================================================
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $brand-font-regular;
  font-weight: $headings-font-weight;
  color: $headings-color;

  &.-on-dark-bg {
    color: $color-white;
  }
}

h1, .h1 {
  //cap-height: 29; 1,6
  //padding-top: 4.5px;
  //margin-bottom: calc(#{$baseline} - 4.5px);
  @include tablet {
    font-size: $font-size-h1-mobile; //34px
    line-height: $baseline*1.5; // 40.5px
  }
  font-size: $font-size-h1; //45px 
  line-height: $baseline*1.8; // 48,6px
  margin-bottom: $baseline;
  letter-spacing: -.02em;
}

h2, .h2 {
  @include tablet {
    font-size: $font-size-h2-mobile; //28px
    line-height: $baseline*1.2;
    margin-top: $baseline*1.2;
  }
  font-size: $font-size-h2; //32px
  line-height: $baseline*1.5; // 40.5px
  //cap-height: 20.25; 1,1
  //padding-top: 6.2px;
  //margin-bottom: calc(#{$baseline} - 6.2px);
  margin-top: $baseline*1.5;
  margin-bottom: $baseline/2;


  &:first-child {
    margin-top: 0;
  }

}


h3, .h3 {
  font-size: $font-size-h3; //26px
  line-height: $baseline*1.2; // 28.8 = 1,18
  margin-top: $baseline;
  margin-bottom: $baseline/2;
  //cap-height: 16.67  = 0,9
  //padding-top: 3.6px;
  //margin-bottom: calc(#{$baseline} - 3.6px);

  &:first-child {
    margin-top: 0;
  }
}

h4, .h4 {
  font-size: $font-size-h4;
  line-height: $baseline;

  &:first-child {
    margin-top: 0;
  }
}

h5, .h5 {
  font-size: $font-size-h5;
  line-height: $baseline;

  &:first-child {
    margin-top: 0;
  }
}


// PARAGRAPHS ================================================================================================================

p {
  margin-bottom: calc(#{$baseline}/2);
  //padding-top: 7.5px;
  //margin-bottom: calc(#{$baseline} - 7.5px);
}

.o-intro-text {
  font-size: $font-size-h3;
  font-weight: 300;
  line-height: 37.8px;
  margin-bottom: 13.5px;
  letter-spacing: -.02rem;
  color: $color-indigo-900;

  &.-on-dark-bg {
    color: $color-white;
  }
}

.o-upper-case {
  text-transform: uppercase;
}

// LINKS ====================================================================================================================

a, .c-hyperlink {
  cursor: pointer;
  text-decoration: underline;
  color: $color-blue-500;

  text-decoration-color: transparentize($color-blue-500, .6);
  text-underline-position: under;

  //padding-top: 7.5px;
  //margin-bottom: calc(#{$baseline} - 7.5px);
  //border-bottom: 2px solid $color-blue-400;

  &:visited {
    text-decoration: underline;
    color: $color-purple-a500;

    text-decoration-color: transparentize($color-purple-a500, .6);
    text-underline-position: under;

  }

  &:hover {
    text-decoration: underline;
    color: darken($color-blue-500, 15%);

    text-decoration-color: transparentize($color-blue-500, .25);
    text-underline-position: under;

  }

  &:focus {
    outline: 2px solid $color-blue-400;
  }

  &:active {
    color: $color-blue-900;
  }
}

//for navigation and intro-block

.o-routing-link {
  font-size: $font-size-h3;
  font-weight: $headings-font-weight;
  line-height: $baseline*1.6;
  cursor: pointer;

  &:visited {
    color: $color-blue-500;

    text-decoration-color: transparentize($color-blue-500, .25);
  }

  &:hover {
    color: darken($color-blue-500, 15%);
  }

  &.-back-on-hover {
    line-height: $baseline*1.2;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:visited {
      text-decoration: none;
    }
    &:before {
      @extend .icon;
      @extend .icon-arrow-left;
      @include screenLarge() {
        margin-left: 0;
      }
      margin-left: -$spacing-l;
      padding-right: .4rem;
    }
  }
}

// new routing link design with arrow-back
.o-context-routing-link {
  font-size: $font-size-h3;
  font-weight: $headings-font-weight;
  display: inline-block;
  margin-top: $spacing-m;
  color: $color-blue-a500;
}


// muted links

.-muted-link {
  cursor: pointer;
  color: $color-grey-700;

  text-decoration-color: $color-grey-700;

  &:visited {
    color: $color-grey-700;

    text-decoration-color: $color-grey-700;
  }

  &:hover {
    color: $color-blue-500;

    text-decoration-color: $color-blue-500;
  }
}

// witte link voor donkere achtergrond
a.o-inverted-link {
  color: $color-white;

  text-decoration-color: $color-white;

  &:hover {
    color: darken($color-white, 15%);
  }

  &:active {
    color: darken($color-white, 15%);
  }

  &:focus {
    outline: 2px solid transparentize($color-white, .25) !important;
  }

}


//when you don't want an underline

a.no-underline-link {
  cursor: pointer;
  text-decoration: none;
  color: $color-blue-500;

  &:visited {
    text-decoration: none;
    color: $color-blue-500;
  }

  &:hover {
    text-decoration: none;
    color: darken($color-blue-500, 15%);
  }

  &:focus {
    outline: 2px solid $color-blue-400;
  }

  &:active {
    color: $color-blue-900;
  }

}

a.o-no-underline-link {
  cursor: pointer;
  text-decoration: none;
  color: $color-blue-500;

  &:visited {
    text-decoration: none;
    color: $color-blue-500;
  }

  &:hover {
    text-decoration: none;
    color: darken($color-blue-500, 15%);
  }

  &:focus {
    outline: 2px solid $color-blue-400;
  }

  &:active {
    color: $color-blue-900;
  }

}

//Lists ================================================================================================================

ul {
  padding-left: 1.2em;
  list-style-position: outside;
  list-style-type: disc;
}

ol {
  padding-left: 1.2em;
  list-style-position: outside;
  list-style-type: decimal;
}


/* put ul bullet in before element? can't do that with ol */

li {
  margin-top: calc(#{$baseline}/2);
  //padding-top: 7.5px;
  //margin-bottom: calc(#{$baseline}*2 - 7.5px);
  margin-bottom: calc(#{$baseline}/2);

}

.list-style-none {
  padding-left: 0;
  list-style-type: none;

  /* to make sure you have indentation when lists are nested */
  li ul.list-style-none {
    padding-left: 1.2em;
  }

  /* to make sure you have indentation when lists are nested and parant list has style none */
  ul:not(ul.list-style-none) {
    margin-left: 1.2em;
  }

}

.o-compact-list {

  li {
    margin-bottom: .3rem;
    margin-top: .3rem;
  }

}

.o-arrows-list {
  @extend .list-style-none;

  li {
    @extend .icon;
    @extend .icon-arrow-right;
    margin-bottom: 1rem;
  }
}

ul.arrows-list-animated {
  @extend .list-style-none;

  li {
    @extend .icon;
    @extend .icon-arrow-right;
    margin-bottom: 1rem;

    &:hover {
      &:before {
        transition: transform .05s ease-in-out;
        //transform: translate3d(3px, 0, 0) scale(1, 1);
        transform: translateX(3px) scale(1,1);
      }
    }
  }

  li:before {
    font-size: 1.1rem;
    transition: transform .05s ease-in-out;
    transform: translateX(0) scale(1, 1);

    filter: blur(0);
  }
}

.checked-list {
  @extend .list-style-none;

  li {
    @extend .icon;
    @extend .icon-check;
    margin-bottom: 1rem;
  }

  li:before {
    margin-right: .3rem;
    font-size: smaller;
  }
}

.checked-list-green {
  @extend .checked-list;

  li:before {
    color: $color-green-700;
  }
}


//captions ================================================================================================================

figcaption {
  font-size: .875rem;
  color: $color-grey-700;
}


// TO DELETE AFTER DRUPAL CHANGES

/*
.text-meta {
  color: $color-grey-700;
}

.text-small {
  font-size: 0.875rem;
  //margin-bottom: .5rem;
} */
// END of TO DELETE AFTER DRUPAL CHANGES


strong, b, .o-font-bold {
  font-weight: 500;
}



// TO DELETE AFTER DRUPAL CHANGES
/*
.routing-link {
  cursor: pointer;
  font-size: $font-size-h3;
  font-weight: $headings-font-weight;
  text-decoration: none;

  &:visited {
    color: $color-blue-500;
  }

  &:hover {
    color: darken($color-blue-500, 15%);
  }

}
*/
a.muted-link {
  cursor: pointer;
  color: $color-grey-700;

  text-decoration-color: $color-grey-700;

  &:hover {
    color: $color-blue-500;

    text-decoration-color: $color-blue-500;
  }
}