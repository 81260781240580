.c-rubriek-block {

  &.-img-center {
    @include align-items(center);
  }
  
  &__imageblock {
    @include align-self(flex-end);
    position: relative;
    height: calc(100% + 3rem);

    grid-area: side;

    [class*='o-wrapper u-bg-'] &, [class*='o-stretch-wrapper u-bg-'] & {
      height: calc(100% + 6rem); //extra height to pop out of padding
    }

    .-img-bottom & {
      height: calc(100% + 3rem);
      margin-bottom: -$spacing-ll;
    }

    .-img-center & {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      height: 100%;
    }
  }

  &__image {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: auto;
    max-width: 100%; //while scaling down for mobile: we don't want the image to spell over the sidearea
    height: 100%;
    transform: translateX(-50%);

    .-img-center & {
      position: unset;
      width: 100%;
      height: auto;
      transform: unset;
    }
  }
}

@include tablet() {
  .c-rubriek-block {
    &__imageblock {
      height: auto;

      [class*='o-wrapper u-bg-'] &, [class*='o-stretch-wrapper u-bg-'] & {
        top: -6rem;
        height: auto; //overrule specificty from  desktop
        margin-bottom: -6rem; //I hate this hacky solution but blame redactie with their "oo everything must be uberflexible"-req, just make everything move like MAGIC
      }

      .-img-bottom & {
        height: auto;
      }
    }

    &__image {
      position: relative;
      max-height: 280px;
    }
  }
}