.has-loading {

  &:hover {
    background-color: transparent !important;
  }

  &__title {
    color: $color-grey-700;

    &:hover {
      color: $color-grey-700 !important;
    }
  }

  span[class^=icon-] {
    color: $color-grey-700;
  }

  input {
    visibility: hidden;
  }

  &__box {
    margin-top: $spacing-s;
    animation: shine 1s infinite ease-out ;
    color: transparent;
    border-radius: $spacing-xxs;
    background-color: $color-grey-100;
    background-image: linear-gradient(278deg, $color-grey-300 1.33%, $color-grey-200 37.2%, $color-grey-200 78.46%, $color-grey-300 100%);
    background-size: 80%;
        
    &.-w50 {
      width: 50%;
    }

    &.-w30 {
      width: 30%;
    }

    &.-w10 {
      width: 10%;
    }

    &.-w60 {
      width: 60%;
    }

    &.-w100 {
      width: 100%;
    }

    &.-h-body {
      height: 1.125rem;
    }

    &.-h-h1 {
      height: 2.825rem;
    }

    &.-h-h2 {
      height: 2rem;
    }

    &.-h-h3 {
      height: 1.625rem;
    }

    &.-h-3 {
      height: 3rem;
    }

    &.-h-5 {
      height: 5rem;
    }

    &.h-100 {
      height: 100%;
    }

    @keyframes shine {
      0% {
        background-position: -100%;
      }

      100% {
        background-position: 100%;
      }
    }


  }

  &__background {
    border: 1px solid $color-grey-700;
    background-color: $color-white !important;
  }
}
