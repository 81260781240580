.c-quoteblock {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  position: relative;

  gap: 2rem;

  &.-img-right {
    @include flex-direction(row-reverse);
  }

  &.-no-img {
    @include justify-content(center);
  }

  &.-img-breakout {
    @include justify-content(flex-end);
  }

  &__text {
    max-width: 60%;

    .-no-img & {
      max-width: 70%;
      text-align: center;
    }
  }

  &__image {
    position: relative;
    width: 33%;
    height: 100%;
    text-align: center;

    img {
      max-width: 80%;

      object-fit: cover;
    }

    .-img-bottom & {
      @include align-self(flex-end);
      bottom: -$spacing-ll; //offset the padding of the  wrapper
    }

    .-img-breakout & {
      position: absolute;
      top: -6rem;
      bottom: -6rem;
      left: 0;
      width: 50%;
      height: calc(100% + 12rem); //height of container + padding-top + padding-bottom of wrapper

      img {
        position: absolute;
        left: 50%;
        max-width: 100%; //when the width of the container becomes smaller than the image, the image starts to scale back
        height: 100%;
        transform: translateX(-50%);

        object-fit: contain;
      }
    }

    .-img-breakout.-img-right & {
      right: 0;
      left: unset;
    }

    .-img-breakout.-img-bottom & {
      top: unset;
      bottom: -3rem;
      height: calc(100% + 9rem);
    }
  }

  &__quote-title {
    font-weight: 600;
    margin-bottom: $spacing-l;
    color: $color-grey-800;
  }

  &__quote-text {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: $color-grey-800;
  }

  &__quote-name {
    font-weight: 500;
    margin-bottom: 0;
    color: $color-blue-900;
  }

}

@include tablet() {

  .c-quoteblock {
    @include flex-direction(column);

    &.-img-right {
      @include flex-direction(column);
    }

    &__text {
      max-width: 100%;
    }

    &__image {
      position: unset !important;
      img {
        //reset breakout css for desktop
        position: unset !important;
        max-width: 100%;
        transform: none !important;
      }

      .-img-bottom & {
        bottom: unset;
        width: 100%;
      }

      .-img-breakout & {
        width: auto;
        height: auto;
      }

      .-img-cover & {
        @include align-self(stretch);
        width: auto;
        margin-top: -$spacing-ll;
        margin-right: -$spacing-m;
        margin-left: -$spacing-m;

        img {
          width: 100%;
        }
      }
    }
  }
}


@include phone() {
  .c-quoteblock {

    &__image {
      width: 100%;
    }
    
    &__text {
      .-no-img & {
        max-width: unset;
      }
    }
  }
}