.c-cta-block {

  &__container {
    position: relative;
    padding-top: 40%;
    border-radius: $spacing-xs;
    background-repeat: no-repeat;
    background-size: cover;

    &.-vacatures {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/cta-block-temp-with-bg.svg');
    }

    &#block2, &.-search {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/cta-block-temp-with-bg-2.svg');
    }

    &#block3, &.-corona {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/cta-block-temp-with-bg-3.svg');
    }

    &#block5, &.-work {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/cta-block-temp-with-bg-5.svg');
    }

    &#helpen,
    &.-helpen {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/helpen.svg');
    }

    &#loopbaantest , &.-test {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/test.svg');
    }


    &#agenda,
    &#test, &.-agenda {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/Agenda.svg');
    }

    &#AI, &.-brein-fitness {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/AI.svg');
    }
    &#formulier, &.-formulier {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/Formulier.svg');
    }

    &#hart, &.-liefde {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/Hart.svg');
    }

    &#megafoon, &.-megafoon {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/Megafoon.svg');
    }

    &#vlieger, &.-vlieger {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/Vlieger.svg');
    }


    &#landbouw, &.-fruit {
      background-image: url('https://cdn.vdab.be/img/components/cta-block/Land%20en%20tuinbouw.svg');
    }

    
    &.-horizontal {
      padding-top: 0;
      padding-right: 40%;
    }
  }

  &__content {
    padding: $spacing-l;
    border-radius: 0 0 $spacing-xs $spacing-xs;
    background-color: $transparent-white-75;

    backdrop-filter: blur(14px);

    .-horizontal & {
      border-radius: $spacing-xs 0 0 $spacing-xs;
    }
  }

  &__button {
    color: $color-grey-900;
    border: 1px solid $color-grey-900;
    background-color: $transparent-white-75;

    &:visited {
      color: $color-grey-900;
    }

    &:hover {
      background-color: $color-grey-200;
    }
  }

}



@include phone() {
  .c-cta-block {

    &__container {
      &.-horizontal {
        padding-top: 40%;
        padding-right: unset;
      }
    }

    &__content {
      .-horizontal & {
        border-radius: 0 0 $spacing-xs $spacing-xs;
      }
    }

  }
}