.c-star-rating {
  display: inline-block;

  &__stars {
    @include flexbox();
  }

  &__star[type=radio] {
    z-index: -1;

    &:not(:first-of-type)+label {
      padding-right: .5rem;
      cursor: pointer;
      color: $color-yellow-700;
    }
  }

  .icon-star {
    font-size: 120%;
  }

  &.-large {
    .icon-star {
      font-size: 150%;
    }
  }
}



.c-star-rating:hover .c-star-rating__star+label {
  color: $color-yellow-500;
}

/* De sterren die NA de geselecteerde ster komen worden hier grijs gemaakt */
.c-star-rating:not(:hover) .c-star-rating__star:checked+label~label, .c-star-rating .c-star-rating__star:hover+label~label {
  color: $color-grey-500;
}

// .rate-stars:not(:hover) input.rate:hover+label~label,
// .rate-stars:not(:hover) input.rate:focus+label~label,